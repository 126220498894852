import { debounce } from 'lodash';
import AsyncSelect from 'react-select/async';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import cx from 'classnames';

import { NoSearchResultsMessage } from 'components/dedicated/SearchField/components/NoSearchResultsMessage';
import { SearchFieldControl } from 'components/dedicated/SearchField/components/SearchFieldControl';
import {
  SearchFieldExportedFunctions,
  SearchFieldProps,
  SuggestionItem,
} from 'components/dedicated/SearchField/SearchField.types';
import { SearchFieldMenuList } from 'components/dedicated/SearchField/components/SearchFieldMenuList';
import { SearchFieldOption } from 'components/dedicated/SearchField/components/SearchFieldOption';
import Box from 'components/core/Box/Box';
import removeApiIdPrefixes from 'utils/removeApiIdPrefixes';

import styles from './SearchField.module.scss';

const CHARACTER_LIMIT = 150;

const SearchField = forwardRef<SearchFieldExportedFunctions, SearchFieldProps>(
  ({ placeholder, onChange, searchSuggestions }, ref) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedSuggestion, setSelectedSuggestion] = useState<SuggestionItem | null>();
    const [hideSuggestionList, setHideSuggestionList] = useState(false);
    const searchBoxDivRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [suggestionList, setSuggestionList] = useState<SuggestionItem[]>([]);
    const [canCacheOptions, setCanCacheOptions] = useState(false);

    const debouncedFetchOptions = debounce(async (inputValue, resolve) => {
      setIsLoading(true);
      const options = await searchSuggestions(inputValue);
      const updatedList = [
        {
          icon: null,
          id: undefined,
          isHiddenOption: true,
          subtitle: '',
          title: '',
        },
        ...options,
      ] as SuggestionItem[];
      setSuggestionList(updatedList);
      setIsLoading(false);

      resolve(updatedList);
    }, 500);

    const loadOptions = (inputValue: string): Promise<SuggestionItem[]> => {
      return new Promise(resolve => {
        if (inputValue.length > CHARACTER_LIMIT) {
          resolve([]);
        } else {
          setSearchTerm(inputValue);
          debouncedFetchOptions(inputValue, resolve);
        }
      });
    };

    const onClearSearch = () => {
      setSearchTerm('');
      setSelectedSuggestion(null);
      onChange(null);
      setCanCacheOptions(false);
    };

    const onSelectedItem = (suggestion: SuggestionItem) => {
      const suggestionObject = suggestion.isHiddenOption
        ? {
            ...suggestion,
            id: searchTerm,
          }
        : suggestion;

      loadOptions(suggestionObject.id);
      if (suggestion.isHiddenOption) {
        setSelectedSuggestion(suggestionObject);
        setHideSuggestionList(true);
        onChange(suggestionObject);
        return;
      }

      const splittedTerm = removeApiIdPrefixes(suggestionObject.id);
      setSearchTerm(splittedTerm);
      setSelectedSuggestion(suggestion);
      onChange(suggestion);
      setCanCacheOptions(true);
      setHideSuggestionList(true);
    };

    const onSearchInputChange = (value: string) => {
      if (value.length > CHARACTER_LIMIT) {
        return;
      }
      setSearchTerm(value);
      setSelectedSuggestion(undefined);
      setHideSuggestionList(false);
      if (value === '') {
        onClearSearch();
      }
    };

    const handleClickOutside = (event: any) => {
      if (searchBoxDivRef.current && !searchBoxDivRef.current.contains(event.target)) {
        setHideSuggestionList(true);
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const onSelectFocus = async () => {
      if (searchTerm) {
        setIsLoading(true);
        setHideSuggestionList(false);
        setIsLoading(false);
        setCanCacheOptions(false);
      }
    };

    const isSuggestionsVisible = !!searchTerm && !hideSuggestionList;

    useImperativeHandle(ref, () => ({
      clearSearch: onClearSearch,
    }));
    return (
      <div ref={searchBoxDivRef} className={styles.searchWrapper}>
        <Box className={styles.searchContainer}>
          <AsyncSelect
            blurInputOnSelect
            cacheOptions={canCacheOptions}
            className={cx(
              styles.selectContainer,
              isSuggestionsVisible && styles.selectContainerWithResults,
            )}
            classNamePrefix='search-select'
            closeMenuOnSelect
            components={{
              Control: SearchFieldControl,
              DropdownIndicator: null,
              LoadingIndicator: undefined,
              MenuList: SearchFieldMenuList,
              Option: SearchFieldOption,
            }}
            defaultOptions={suggestionList}
            inputValue={searchTerm}
            isLoading={isLoading}
            isSearchable
            loadOptions={loadOptions}
            menuIsOpen={isSuggestionsVisible}
            noOptionsMessage={NoSearchResultsMessage}
            onBlur={() => setCanCacheOptions(true)}
            onChange={val => onSelectedItem(val as SuggestionItem)}
            onFocus={() => onSelectFocus()}
            onInputChange={(value, actionMeta) =>
              actionMeta.action === 'input-change' && onSearchInputChange(value)
            }
            placeholder={placeholder}
            styles={{
              input: base => ({
                ...base,
                display: 'flex',
                height: '100%',
                margin: 0,
                opacity: '1 !important',
                overflowX: 'hidden',
                visibility: 'visible',
              }),
              option: base => ({
                ...base,
                backgroundColor: 'transparent',
                borderRadius: 8,
                padding: 0,
              }),
              placeholder: (base, { isFocused }) => ({
                ...base,
                opacity: isFocused ? 0 : 1,
              }),
              valueContainer: base => ({
                ...base,

                height: '100%',
                marginLeft: 13,
                padding: 0,
              }),
            }}
            tabIndex={0}
            value={selectedSuggestion}
            {...{
              isSuggestionsVisible,
              onClearSearch,
              searchTerm,
              selectedSuggestion,
            }}
          />
        </Box>
      </div>
    );
  },
);

export { SearchField };
