import { format } from 'date-fns';

import { FilterTypes } from 'components/core/CheckboxDropdown/CheckboxDropdown.types';
import { PREBUILT_FILTERS } from 'components/core/DatePicker/DatePicker.types';

export const formatDatesForCreatedAtTransactionsQuery = (
  start: Date,
  end?: Date | null,
): { createdAt: Record<string, string> } => {
  return {
    createdAt: {
      end: end ? format(end, 'yyyy-MM-dd') : '',
      start: format(start!, 'yyyy-MM-dd'),
    },
  };
};

export const buildQueryObject = (filters: Record<string, any>): Record<string, string> => {
  return Object.fromEntries(
    Object.entries(filters)
      .filter(([key]) => key !== FilterTypes.DATE_RANGE)
      .map(([key, valueArray]) => [key, (valueArray as string[])?.join(',')]),
  );
};

export const returnActiveFilters = ({ filtersObject }: { filtersObject: any }): string[] => {
  return Object.keys(filtersObject).filter(key => {
    const filterValue = filtersObject[key];

    if (key === FilterTypes.DATE_RANGE) {
      if (filterValue.isDefaultFilter) {
        // default date filter should not be considered an active filter
        return false;
      }
      return filterValue.filter === PREBUILT_FILTERS.ALL_TIME || !!filterValue.start;
    }

    return filterValue.length > 0;
  });
};
