import {
  GetDayStyleClassProps,
  IsInHoverRangeProps,
} from 'components/core/DatePicker/DatePicker.types';

/**
 * This function checks if a date is in between a given selected date range
 * The main purpose of this function is to help choose which CSS classes apply for the dates in the range.
 *
 * for example:
 *  Imagine that there is a period selected between Jan 1 and Jan 10, if the date passed in the parameter is Jan 5th it should return true,
 *  if I pass Jan 11 or Dec 31st, it should be false
 */

export const isInHoverRange = ({
  date,
  hoveredDate,
  startDate,
  endDate,
}: IsInHoverRangeProps): boolean => {
  if (!hoveredDate || !startDate || !endDate) {
    return false;
  }

  const dateTime = date.getTime();
  const hoveredTime = hoveredDate.getTime();
  const startTime = startDate.getTime();
  const endTime = endDate.getTime();

  if (hoveredTime < startTime) {
    return dateTime >= hoveredTime && dateTime <= startTime;
  }

  if (hoveredTime > endTime) {
    return dateTime >= endTime && dateTime <= hoveredTime;
  }

  return false;
};

/**
 *
 * this function is executed for each day in the calendar, and its purpose is to return all classes for that given day.
 * The Date parameter means the current day which we'll be selecting its classes
 * If we return '' the default classes only would be applied
 *
 * @param date the date you want to apply the styling
 * @param startDate the current selected start date of a range
 * @param endDate the current selected end date of a range
 * @param hoveredDate the current date of which the mouse cursor is hovering, if any
 *
 */
export const getStyleClassForDate = ({
  date,
  startDate,
  endDate,
  hoveredDate,
}: GetDayStyleClassProps): string => {
  // Early return if key dates are missing
  if (!hoveredDate || !startDate || !endDate) {
    return '';
  }

  const hoveredTime = hoveredDate.getTime();
  const startTime = startDate.getTime();
  const endTime = endDate.getTime();
  const dateTime = date.getTime();

  // Ignore if hovered date matches start or end
  if (hoveredTime === startTime || hoveredTime === endTime) {
    return '';
  }

  const isHoveringToTheEnd = hoveredDate > startDate;
  const isIncreasingEndRange = hoveredDate > endDate;
  const isDateBeingHovered = hoveredTime === dateTime;
  const isStartDay = dateTime === startTime;
  const isEndDay = dateTime === endTime;
  const isSingleDate = startTime === endTime;
  const isDecreasingEndRange = isHoveringToTheEnd && !isIncreasingEndRange;
  const isDayOutsideOfNewSelectionRange = date > startDate && date < endDate && date > hoveredDate;

  // Handle specific range behavior when hovering towards the end
  if (isDecreasingEndRange) {
    if (isDayOutsideOfNewSelectionRange) {
      return 'hover-range-empty';
    }

    if (isDateBeingHovered) {
      return 'hover-range-active-item-decrease';
    }
  }

  // Active hover styling
  if (isDateBeingHovered) {
    if (isHoveringToTheEnd) {
      return 'hover-range-active-item hover-range-connection-left';
    }
    return 'hover-range-active-item hover-range-connection-right';
  }

  // Handle start date styling
  if (isStartDay) {
    if (isSingleDate) {
      return `hover-range-active-item ${hoveredTime > startTime ? 'hover-range-connection-right' : 'hover-range-connection-left'}`;
    }
    if (!isHoveringToTheEnd) {
      return 'hover-range-previous-selected-start-date';
    }
  }

  // Handle end date styling
  if (isEndDay && isHoveringToTheEnd) {
    if (isIncreasingEndRange) {
      return 'hover-range-previous-selected-end-date';
    }
    return 'hover-range-previous-selected-end-date-empty';
  }

  // Default hover range styling
  if (isInHoverRange({ date, endDate, hoveredDate, startDate })) {
    return 'hover-range';
  }

  return '';
};
