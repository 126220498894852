import React, { FC, useMemo } from 'react';

import { NoSearchResultsMessage } from 'components/dedicated/SearchField/components/NoSearchResultsMessage';
import { SearchFieldMenuListProps } from 'components/dedicated/SearchField/SearchField.types';
import Box from 'components/core/Box/Box';
import Spinner from 'components/core/Spinner/Spinner';
import Text from 'components/core/Text/Text';

import styles from '../SearchField.module.scss';

export const SearchFieldMenuList: FC<SearchFieldMenuListProps> = ({ children, selectProps }) => {
  const hasVisibleSuggestions = useMemo(
    () => selectProps.options.filter(option => !option.isHiddenOption).length > 0,
    [selectProps],
  );

  if (!hasVisibleSuggestions && !selectProps.isLoading) {
    return (
      <Box className={styles.searchSuggestionResultList} padding={6}>
        <NoSearchResultsMessage inputValue={selectProps.inputValue} />
      </Box>
    );
  }

  return (
    <Box className={styles.searchSuggestionResultList} padding={4}>
      {selectProps.isLoading ? (
        <Spinner />
      ) : (
        <>
          {selectProps.options.length > 0 && (
            <Text marginBottom={4.4} marginLeft={4} marginTop={0.5} variant='sectionHeaderStandard'>
              Best Matches Found
            </Text>
          )}
          <Box alignItems='flex-start' display='flex' flexDirection='column' gap={1}>
            {children}
          </Box>
        </>
      )}
    </Box>
  );
};
