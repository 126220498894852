import React, { FC, useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';

import {
  DatePickerHeadlessExportedFunctions,
  DatePickerProps,
  PREBUILT_FILTERS,
} from 'components/core/DatePicker/DatePicker.types';
import { DatePickerHeadlessHeader } from 'components/core/DatePicker/components/DatePickerHeader';
import { DatePickerPrebuiltFilters } from 'components/core/DatePicker/components/DatePickerPrebuiltFilters';
import { applyFilterFor } from 'components/core/DatePicker/components/DatepickerPrebuiltFilters.utils';
import { getStyleClassForDate } from 'components/core/DatePicker/DatePicker.utils';
import Box from 'components/core/Box/Box';
import Button from 'components/core/Button/Button';

import styles from './DatePicker.module.scss';

export const DatePicker: FC<DatePickerProps> = ({
  onChange,
  initialFilter,
  selectedEndDate,
  selectedStartDate,
  onClearFilters,
}) => {
  const previousMonth = new Date();
  previousMonth.setMonth(previousMonth.getMonth() - 1);
  const today = new Date();
  const [startDate, setStartDate] = useState<Date | null>(selectedStartDate as Date);
  const [endDate, setEndDate] = useState<Date | null>(selectedEndDate as Date);
  const [activeFilter, setActiveFilter] = useState<PREBUILT_FILTERS>(
    initialFilter || PREBUILT_FILTERS.LAST_90_DAYS,
  );
  const [isApplyDisabled, setIsApplyDisabled] = useState(false);
  const [isClearFiltersDisabled, setIsClearFiltersDisabled] = useState(true);
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);
  const headerRef = useRef<DatePickerHeadlessExportedFunctions>(null);

  const calendarStart = new Date('2021-01-02');
  const navigateCalendarTo = ({ date, filter }: { date: Date; filter: PREBUILT_FILTERS }) => {
    headerRef.current?.changeMonthView({
      appliedFilter: filter as PREBUILT_FILTERS,
      endDate: date,
    });
  };

  const clearFilters = () => {
    setActiveFilter(PREBUILT_FILTERS.CUSTOM);

    setIsClearFiltersDisabled(true);
    setStartDate(null);
    setEndDate(null);
    if (onClearFilters) {
      onClearFilters();
    }
    navigateCalendarTo({ date: today, filter: PREBUILT_FILTERS.CUSTOM });
    setEndDate(null);
    setStartDate(null);
  };

  const onClickCustomFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setIsApplyDisabled(true);
    setIsClearFiltersDisabled(true);
    setActiveFilter(PREBUILT_FILTERS.CUSTOM);
  };

  const onFilterChange = ({ newStartDate, newEndDate, filter }) => {
    if (filter === PREBUILT_FILTERS.CUSTOM) {
      return;
    }
    setEndDate(newEndDate);
    setStartDate(newStartDate);
    setIsClearFiltersDisabled(false);
    navigateCalendarTo({ date: newEndDate!, filter });
    setIsApplyDisabled(false);
  };

  const onCalendarDateChange = ({ start, end }) => {
    setActiveFilter(PREBUILT_FILTERS.CUSTOM);
    setIsClearFiltersDisabled(false);
    setIsApplyDisabled(false);
    if (!startDate && !endDate) {
      setStartDate(start);
      setEndDate(end);
      return;
    }

    if (hoveredDate) {
      if (hoveredDate < startDate!) {
        setStartDate(hoveredDate);
      } else {
        setEndDate(hoveredDate);
      }
    }
  };

  const onApplyFilters = () => {
    const isSingleDate = !endDate || startDate?.getTime() === endDate.getTime();

    const updatedEndDate = activeFilter === PREBUILT_FILTERS.ALL_TIME ? null : endDate;
    const updatedStartDate = activeFilter === PREBUILT_FILTERS.ALL_TIME ? null : startDate;
    onChange({
      activeFilter,
      endDate: isSingleDate ? startDate : updatedEndDate,
      isSingleDate: !endDate || startDate?.getTime() === endDate.getTime(),
      startDate: updatedStartDate,
    });
  };

  const onDayMouseEnter = date => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(0, 0, 0);
    setHoveredDate(date);
  };

  const onDayMouseLeave = () => {
    setHoveredDate(null);
  };

  useEffect(() => {
    // load the default or applied filter in the first load
    if (activeFilter !== PREBUILT_FILTERS.CUSTOM) {
      const { newEndDate, newStartDate } = applyFilterFor(activeFilter, today);
      setEndDate(newEndDate);
      setStartDate(newStartDate);
      navigateCalendarTo({ date: newEndDate!, filter: activeFilter });
    }

    if (selectedEndDate || selectedStartDate) {
      setIsClearFiltersDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      alignItems='stretch'
      className={styles.datePickerDetailsContainer}
      display='flex'
      flexDirection='column'
    >
      <Box alignItems='stretch' className={styles.datePickerContainer} display='flex'>
        <Box
          alignItems='flex-start'
          className={styles.prebuiltHeadersContainer}
          display='flex'
          flexDirection='column'
          padding={4}
        >
          <DatePickerPrebuiltFilters
            activeFilter={activeFilter}
            onClickCustomFilter={() => onClickCustomFilter()}
            onFilterChange={({ newEndDate, newStartDate, filter }) =>
              onFilterChange({ filter, newEndDate, newStartDate })
            }
            setActiveFilter={setActiveFilter}
            today={today}
          />
        </Box>

        <Box alignItems='stretch' display='flex' flexDirection='column' padding={4}>
          <ReactDatePicker
            calendarClassName={styles.datePicker}
            dayClassName={date => getStyleClassForDate({ date, endDate, hoveredDate, startDate })}
            endDate={endDate as Date}
            inline
            maxDate={today}
            minDate={calendarStart}
            monthsShown={2}
            onChange={([start, end]) => onCalendarDateChange({ end, start })}
            onClickOutside={() => setHoveredDate(null)}
            onDayMouseEnter={onDayMouseEnter}
            onDayMouseLeave={onDayMouseLeave}
            onMonthMouseLeave={() => onDayMouseLeave()}
            openToDate={previousMonth}
            renderCustomHeader={props => (
              <DatePickerHeadlessHeader
                ref={headerRef!}
                activeFilter={activeFilter}
                endDate={endDate}
                startDate={startDate}
                today={today}
                {...props}
              />
            )}
            selectsRange
            startDate={startDate as Date}
            swapRange
          />
        </Box>
      </Box>

      <Box
        className={styles.datePickerBottomContainer}
        display='flex'
        gap={2}
        justifyContent='flex-end'
        padding={4}
      >
        <Button
          isDisabled={isClearFiltersDisabled}
          onClick={() => clearFilters()}
          size='small'
          variant='textCta'
        >
          Clear
        </Button>
        <Button
          isDisabled={isApplyDisabled}
          onClick={() => onApplyFilters()}
          size='small'
          variant='primary'
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
};
