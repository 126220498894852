import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

// eslint-disable-next-line no-restricted-syntax
import * as icons from 'components/core/Svg/icons';
import { AssetType } from 'constants/assetTypes.types';
import { getDetailedAssetName, getUriIdAndChainId, isFiat } from 'utils/format';
import AssetPill from 'components/core/AssetPill/AssetPill';
import Dropdown from 'components/core/Dropdown/Dropdown';
import Icon from 'components/core/Icon/Icon';
import MenuItem from 'components/core/MenuItem/MenuItem';
import Text from 'components/core/Text/Text';
import Tooltip from 'components/core/Tooltip/Tooltip';

import { AssetTypeFieldProps } from './AssetTypeField.types';
import { getAssetTypePairRule, validResult } from './AssetTypeField.utils';
import styles from './AssetTypeField.module.scss';

import InputField from '../InputField/InputField';

const AssetTypeField: FC<AssetTypeFieldProps> = ({
  amountLabel,
  amountFieldValue,
  amountValidatorFunction,
  assetType,
  assetTypes,
  className,
  isDisabled,
  isReadOnly,
  readOnlyTooltipMessage = '',
  onAmountChange,
  onAssetTypeChange,
  onValidationChange,
  assetTypePair,
}) => {
  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');

  const { ethAssetTypes, fiatAssetTypes } = useMemo(() => {
    return {
      ethAssetTypes: assetTypes
        ?.filter(a => !isFiat(a.id))
        .sort((a, b) => getDetailedAssetName(a).localeCompare(getDetailedAssetName(b))),
      fiatAssetTypes: assetTypes
        ?.filter(a => isFiat(a.id))
        .sort((a, b) => getDetailedAssetName(a).localeCompare(getDetailedAssetName(b))),
    };
  }, [assetTypes]);

  useEffect(() => {
    setError('');
  }, [assetType, assetTypePair]);

  useEffect(() => {
    setAmount(amountFieldValue?.toString() || '');
  }, [amountFieldValue]);

  const assetTypePairRule = useMemo(
    () => getAssetTypePairRule(assetType, assetTypePair),
    [assetTypePair, assetType],
  );

  const amountChanged = (value: string) => {
    setAmount(value);

    if (onAmountChange) {
      onAmountChange(value);
    }
  };

  const getTooltipLabel = useCallback(
    (asset: AssetType) => {
      return (
        asset.disabledMessage ||
        assetTypePair?.disabledMessage ||
        'This trading pair is currently unavailable for your account. To\u00a0enable it, please contact our sales team for further details.'
      );
    },
    [assetTypePair],
  );

  useEffect(() => {
    if (!assetType) {
      return;
    }

    const { status, errorMessage } =
      amountFieldValue && amountValidatorFunction
        ? amountValidatorFunction(amountFieldValue, assetType, assetTypePairRule)
        : validResult;

    setError(errorMessage);

    if (onValidationChange) {
      onValidationChange(status);
    }
  }, [amountFieldValue, amountValidatorFunction, assetType, assetTypePairRule, onValidationChange]);

  const filterKeyPress = (event: React.KeyboardEvent) => {
    if ([0, 69, 189].includes(event.keyCode)) {
      event.preventDefault();
    }
  };

  const filterClipboardPaste = (event: React.ClipboardEvent) => {
    if (!/^[0-9.,]+$/.test(event.clipboardData.getData('text/plain'))) {
      event.preventDefault();
    }
  };

  return (
    <div className={className}>
      <Tooltip isHidden={!isReadOnly} label={readOnlyTooltipMessage}>
        <InputField
          error={error}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          isRequired
          label={amountLabel}
          onChange={({ target: { value } }) => amountChanged(value)}
          onKeyDown={filterKeyPress}
          onPaste={filterClipboardPaste}
          step='any'
          type='number'
          value={amount}
        >
          {assetTypes !== undefined ? (
            <Dropdown
              className={styles.floatRight}
              isDisabled={assetTypes.length === 0}
              label={getUriIdAndChainId(assetType) || 'Select Currency'}
              LeadingIcon={
                assetType?.iconName && (
                  <Icon className={styles.circle} iconName={assetType?.iconName} size={1.8} />
                )
              }
              menuPosition='right'
              menuWidth='wide'
            >
              {fiatAssetTypes && fiatAssetTypes.length > 0 && (
                <Text className={styles.dropdownGroup} variant='bodyCopySmall'>
                  Fiat
                </Text>
              )}
              {fiatAssetTypes?.map(fiatAssetType => (
                <Tooltip
                  key={fiatAssetType.id}
                  isHidden={!fiatAssetType?.isDisabled}
                  label={getTooltipLabel(fiatAssetType)}
                >
                  <MenuItem
                    className={styles.menuItemCircle}
                    isDisabled={fiatAssetType.isDisabled}
                    label={getDetailedAssetName(fiatAssetType)}
                    onClick={() => {
                      if (onAssetTypeChange && fiatAssetType.id !== assetType?.id) {
                        onAssetTypeChange(fiatAssetType);
                      }
                    }}
                    svgImg={icons[fiatAssetType.iconName]}
                    variant='dropdown'
                  />
                </Tooltip>
              ))}
              {ethAssetTypes && ethAssetTypes.length > 0 && (
                <Text className={styles.dropdownGroup} variant='bodyCopySmall'>
                  Crypto
                </Text>
              )}
              {ethAssetTypes?.map(ethAssetType => (
                <Tooltip
                  key={ethAssetType.id}
                  isHidden={!ethAssetType?.isDisabled}
                  label={getTooltipLabel(ethAssetType)}
                >
                  <MenuItem
                    className={styles.menuItemCircle}
                    isDisabled={ethAssetType.isDisabled}
                    label={getDetailedAssetName(ethAssetType)}
                    onClick={() => {
                      if (onAssetTypeChange && ethAssetType.id !== assetType?.id) {
                        onAssetTypeChange(ethAssetType);
                      }
                    }}
                    svgImg={icons[ethAssetType.iconName]}
                    variant='dropdown'
                  />
                </Tooltip>
              ))}
            </Dropdown>
          ) : (
            <AssetPill assetType={assetType} variant='inInput' />
          )}
        </InputField>
      </Tooltip>
    </div>
  );
};

export default AssetTypeField;
