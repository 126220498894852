import { format } from 'date-fns';

import { CheckboxDropdownSection } from 'components/core/CheckboxDropdown/CheckboxDropdown.types';
import {
  TRANSACTION_COMPLETED_FILTER_STATUSES,
  TRANSACTION_PENDING_FILTER_STATUSES,
} from 'constants/transactions';
import { TransactionStatus, TransactionType } from 'store/api/api.types';

export const TRANSACTIONS_FILTER_PENDING = TRANSACTION_PENDING_FILTER_STATUSES.join(',');
export const TRANSACTIONS_FILTER_COMPLETED = TRANSACTION_COMPLETED_FILTER_STATUSES.join(',');
export const transactionTypeDropdownSections: CheckboxDropdownSection[] = [
  {
    name: 'Inflows',
    options: [
      {
        label: 'Deposits',
        value: TransactionType.Deposit,
      },
      {
        label: 'Onramps',
        value: TransactionType.Onramp,
      },
    ],
  },
  {
    name: 'Outflows',
    options: [
      {
        label: 'Withdrawals',
        value: TransactionType.Withdrawal,
      },
      {
        label: 'Offramps',
        value: TransactionType.Offramp,
      },
    ],
  },
];

export const transactionStatusDropdownSections: CheckboxDropdownSection[] = [
  {
    name: 'Pending',
    options: [
      {
        label: 'Awaiting Funds',
        value: TransactionStatus.AwaitingFunds,
      },
      {
        label: 'Awaiting Compliance Review',
        value: TransactionStatus.AwaitingComplianceReview,
      },
      {
        label: 'In Compliance Review',
        value: TransactionStatus.InComplianceReview,
      },
      {
        label: 'Compliance Approved',
        value: TransactionStatus.ComplianceApproved,
      },
      {
        label: 'Processing',
        value: `${TransactionStatus.ProcessingSettlement},${TransactionStatus.ProcessingPayment},${TransactionStatus.ProcessingWithdrawal},${TransactionStatus.ProcessingCancellation}`,
      },
    ],
  },
  {
    name: 'Completed',
    options: [
      {
        label: 'Completed',
        value: TransactionStatus.Completed,
      },
      {
        label: 'Cancelled',
        value: TransactionStatus.Cancelled,
      },
      {
        label: 'Compliance Rejected',
        value: TransactionStatus.ComplianceRejected,
      },
    ],
  },
];

const startDate = new Date();
const today = new Date();
startDate.setDate(today.getDate() - 90);

export const DEFAULT_TRANSACTION_FILTERS = {
  createdAt: {
    end: format(today, 'yyyy-MM-dd'),
    start: format(startDate, 'yyyy-MM-dd'),
  },
};
