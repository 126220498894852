import { generatePath, useLocation, useParams } from 'react-router-dom';
import React, { ReactElement, useMemo, useState } from 'react';

import { ApiTransactionSummary, PaginationMode } from 'store/api/api.types';
import {
  DEFAULT_TRANSACTION_FILTERS,
  TRANSACTIONS_FILTER_COMPLETED,
  TRANSACTIONS_FILTER_PENDING,
} from 'components/views/app/organization/TransactionsView/TransactionsView.constants';
import { ORGANIZATION_ROUTES, ORGANIZATION_TRANSACTIONS_ROUTES } from 'router/constants';
import { SuggestionItem } from 'components/dedicated/SearchField/SearchField.types';
import { TabProps } from 'components/core/Tab/Tab.types';
import { TransactionFilters } from 'components/views/app/organization/TransactionsView/components/TransactionFilters/TransactionFilters';
import {
  TransactionFiltersProps,
  TransactionsViewTabs,
} from 'components/views/app/organization/TransactionsView/TransactionsView.types';
import { TransactionSearchField } from 'components/views/app/organization/TransactionsView/components/TransactionSearchField';
import { download } from 'components/core/Svg/icons';
import { useGetOrganizationQuery } from 'store/api/rootApi';
import { useGetTransactionSummariesQuery } from 'store/api/platformApi';
import { useTransactionReportExport } from 'hooks/useTransactionReportExport';
import BackToTopButton from 'components/core/BackToTopButton/BackToTopButton';
import Box from 'components/core/Box/Box';
import Button from 'components/core/Button/Button';
import ContentSection from 'components/core/ContentSection/ContentSection';
import NoResultsMessage from 'components/core/NoResultsMessage/NoResultsMessage';
import PaginationBar from 'components/core/PaginationBar/PaginationBar';
import Spinner from 'components/core/Spinner/Spinner';
import Svg from 'components/core/Svg/Svg';
import Tabs from 'components/core/Tabs/Tabs';
import TransactionsList from 'components/dedicated/organization/TransactionsList/TransactionsList';
import usePaginationLinks from 'hooks/usePaginationLinks';
import usePaginationQuery from 'hooks/usePaginationQuery';

import styles from './TransactionsView.module.scss';

const TransactionsView = (): ReactElement => {
  const { pathname } = useLocation();
  const { organizationId } = useParams() as { organizationId: string };
  const { handleOnLimitSelect, pageNumber, pageSize } = usePaginationQuery();
  const { data: organizationData } = useGetOrganizationQuery({ organizationId });
  const [searchFilters, setSearchFilters] = useState<Record<string, string>>({});
  const [transactionFilters, setTransactionFilters] = useState<TransactionFiltersProps>(
    DEFAULT_TRANSACTION_FILTERS,
  );

  const [isOnlyDefaultFiltersApplied, setIsOnlyDefaultFiltersApplied] = useState(true);
  const [transactionReportFilters, setTransactionReportFilters] = useState<Record<string, string>>(
    {},
  );

  const { data: pendingTransactions, isLoading: isLoadingPendingTransactions } =
    useGetTransactionSummariesQuery({
      organizationId,
      params: {
        filter: {
          status: TRANSACTIONS_FILTER_PENDING,
        },
        page: {
          mode: PaginationMode.Offset,
          number: '1',
          size: 101, // to get the count of pending transactions
        },
      },
    });
  const isFilteringByPending = useMemo(
    () => pathname.includes(ORGANIZATION_TRANSACTIONS_ROUTES.PENDING.relative),
    [pathname],
  );

  const activeTab = useMemo(() => {
    if (isFilteringByPending) {
      return TransactionsViewTabs.PENDING;
    }

    if (pathname.includes(ORGANIZATION_TRANSACTIONS_ROUTES.COMPLETED.relative)) {
      return TransactionsViewTabs.COMPLETED;
    }

    return TransactionsViewTabs.ALL;
  }, [pathname, isFilteringByPending]);

  const transactionStatusFilterValue = useMemo(() => {
    if (pathname.includes(ORGANIZATION_TRANSACTIONS_ROUTES.COMPLETED.relative)) {
      return TRANSACTIONS_FILTER_COMPLETED;
    }
    if (isFilteringByPending) {
      return TRANSACTIONS_FILTER_PENDING;
    }
    return undefined;
  }, [isFilteringByPending, pathname]);

  const canExportTransactionReport =
    organizationData?.platform?.attributes?.features?.clientTransactionsReportEnabled;

  const { exportTransactionReport, isExporting } = useTransactionReportExport(organizationId, {
    status: transactionStatusFilterValue,
    ...transactionReportFilters,
  });

  const {
    data: transactionsSummaries,
    isLoading: isLoadingMainQuery,
    isFetching,
  } = useGetTransactionSummariesQuery({
    organizationId,
    params: {
      filter: {
        ...(searchFilters ? { ...searchFilters } : {}),
        ...(transactionStatusFilterValue ? { status: transactionStatusFilterValue } : {}),
        ...(transactionFilters && { ...transactionFilters }),
      },
      page: {
        mode: PaginationMode.Offset,
        number: pageNumber || undefined,
        size: pageSize.value,
      },
    },
  });

  const { handleOnNextPage, handleOnPreviousPage, handleOnSelectPage } = usePaginationLinks(
    PaginationMode.Offset,
    transactionsSummaries?.links,
    pageSize.value,
  );

  const isLoading = isLoadingMainQuery || isLoadingPendingTransactions;
  const transactionSummaries = transactionsSummaries?.data || [];

  const tabs: TabProps[] = [
    {
      label: 'All',
      to: generatePath(ORGANIZATION_TRANSACTIONS_ROUTES.ALL.absolute, {
        organizationId,
      }),
    },
    {
      count:
        (pendingTransactions?.data.length || 0) > 100
          ? '100+'
          : pendingTransactions?.data.length || 0,
      label: 'Pending',
      to: generatePath(ORGANIZATION_TRANSACTIONS_ROUTES.PENDING.absolute, {
        organizationId,
      }),
    },
    {
      label: 'Completed',
      to: generatePath(ORGANIZATION_TRANSACTIONS_ROUTES.COMPLETED.absolute, {
        organizationId,
      }),
    },
  ];

  const onSearchChange = (value: SuggestionItem<ApiTransactionSummary> | undefined) => {
    // navigates to the first page of the list
    if (handleOnSelectPage) {
      handleOnSelectPage(1);
    }
    setSearchFilters(value ? { id: value.id } : {});
  };

  const onTransactionFiltersChange = ({ filters, defaultFilterOnly }) => {
    setIsOnlyDefaultFiltersApplied(defaultFilterOnly);

    // navigates to the first page of the list
    if (handleOnSelectPage) {
      handleOnSelectPage(1);
    }
    setTransactionFilters({
      ...filters,
      status: filters.status ? filters.status : transactionStatusFilterValue,
    });

    // There are differences in the parameter names between the transaction summaries endpoint and the reports endpoint, with that we have to create a new filter object specifically to the reports endpoint

    const reportQueryObject = {
      fromDate: filters.createdAt.start,
      status: filters.status ? filters.status : transactionStatusFilterValue,
      toDate: filters.createdAt.end,
      type: filters.transactionType,
    };

    setTransactionReportFilters(reportQueryObject);
  };

  const noResultsProps = useMemo(() => {
    const hasSearch = Object.keys(searchFilters).length > 0;
    const hasFilters = !isOnlyDefaultFiltersApplied;

    if (hasSearch || hasFilters) {
      return {
        button: undefined,
        header: 'No Matches Found',
        message: hasSearch
          ? 'Try searching using different keywords or variations.'
          : 'Try adjusting your filters for more results.',
      };
    }

    if (isFilteringByPending) {
      return {
        button: undefined,
        header: 'No Pending Transactions',
        message:
          'You have no pending transactions at the moment. Any new transactions you initiate will appear here.',
      };
    }

    return {
      button: {
        label: 'Send Money',
        to: generatePath(ORGANIZATION_ROUTES.SEND_MONEY.absolute, {
          organizationId,
        }),
      },
      header: 'No Transactions Yet',
      message: `It looks like you haven't made any transactions yet. Once you create a transaction, you’ll be able to view its status, details, and summary here.`,
    };
  }, [searchFilters, isOnlyDefaultFiltersApplied, isFilteringByPending, organizationId]);

  if (isLoading) {
    return <Spinner flexGrow={1} size='large' />;
  }

  return (
    <>
      <Tabs isFullWidth marginTop={4} tabs={tabs} />

      <div className={styles.listContainer}>
        <ContentSection className={styles.resultsWrapper}>
          <Box alignItems='stretch' display='flex' flexDirection='column'>
            <Box className={styles.transactionSearchAndFiltersContainer} display='flex' gap={4}>
              <Box display='flex' flexGrow={2} maxWidth='84%'>
                <TransactionSearchField
                  onSearchChange={onSearchChange}
                  organizationId={organizationId}
                  transactionStatusFilterValue={transactionStatusFilterValue}
                />
              </Box>
              <TransactionFilters
                activeTab={activeTab}
                onFilterChange={filters => onTransactionFiltersChange(filters)}
              />
              {canExportTransactionReport && (
                <Button
                  isDisabled={isExporting || isFetching || transactionSummaries.length === 0}
                  isLoading={isExporting}
                  label={isExporting ? 'Exporting...' : 'Export CSV'}
                  LeadingIcon={<Svg img={download} size={1.8} />}
                  onClick={() => exportTransactionReport()}
                  size='normal'
                />
              )}
            </Box>
            <Box id='portal-filter' />
          </Box>
          <Box marginTop={10}>
            {!isLoading && transactionSummaries.length > 0 && (
              <>
                {isFetching && <Spinner flexGrow={1} />}
                {!isFetching && transactionSummaries.length > 0 && (
                  <>
                    <TransactionsList
                      organizationId={organizationId}
                      shouldSectionByMonths
                      transactionSummaries={transactionSummaries}
                    />
                    <BackToTopButton />
                    <PaginationBar
                      className={styles.paginationBar}
                      lastPageNumber={transactionsSummaries?.links?.last}
                      onLimitSelect={handleOnLimitSelect}
                      onNextPage={handleOnNextPage}
                      onPrevPage={handleOnPreviousPage}
                      onSelectPage={handleOnSelectPage}
                      pageNumber={pageNumber}
                      pageSize={pageSize}
                      resultsCount={transactionSummaries.length}
                    />
                  </>
                )}
              </>
            )}
            <Box alignItems='flex-start' display='flex' justifyContent='center' marginTop={24}>
              {!isFetching && transactionSummaries.length === 0 && (
                <NoResultsMessage
                  button={noResultsProps.button}
                  header={noResultsProps.header}
                  message={noResultsProps.message}
                />
              )}
            </Box>
          </Box>
        </ContentSection>
      </div>
    </>
  );
};

export default TransactionsView;
