import { useLocation } from 'react-router-dom';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';

import { ButtonVariant } from 'components/core/Button/Button.types';
import { chevronDown } from 'components/core/Svg/icons';
import { getScrollableParent } from 'utils/getScrollableParent';
import Button from 'components/core/Button/Button';
import ReactPortal from 'components/core/ReactPortal/ReactPortal';
import Svg from 'components/core/Svg/Svg';
import generateUid from 'utils/generateUid';

import DropdownProps, { DropdownExportedFunctions } from './Dropdown.types';
import styles from './Dropdown.module.scss';

const Dropdown = forwardRef<DropdownExportedFunctions, DropdownProps>(
  (
    {
      children,
      className,
      dataTestId,
      menuPosition = 'right',
      menuWidth = 'normal',
      showArrow,
      LeadingIcon,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      keepOpen = false,
      TrailingIcon,
      variant = 'dropdownOpaque',
      dropdownContentClassname,
      ...buttonProps
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownMenuPosition, setDropdownMenuPosition] = useState({
      left: 'auto',
      top: 'auto',
    });
    const location = useLocation();
    const dropdownButtonRef = useRef<HTMLButtonElement>(null);
    const dropdownMenuRef = useRef<HTMLDivElement>(null);
    const isOpenState = useRef(isOpen);

    const toggleMenu = isOpenCurrent => {
      isOpenState.current = isOpenCurrent;
      setIsOpen(isOpenCurrent);
    };

    useImperativeHandle(ref, () => ({
      toggleMenu: () => toggleMenu(!isOpen),
    }));

    const updateDropdownMenuPosition = useCallback(() => {
      if (isOpen && dropdownButtonRef.current && dropdownMenuRef.current) {
        const dropdownButtonRect = dropdownButtonRef.current.getBoundingClientRect();
        const dropdownMenuRect = dropdownMenuRef.current.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        const rightScreenEdgeOffset = 16;

        const absoluteButtonTop = dropdownButtonRect.top + window.scrollY;
        const absoluteButtonLeft = dropdownButtonRect.left + window.scrollX;
        const absoluteButtonRight = dropdownButtonRect.right + window.scrollX;

        let newLeftPosition;
        if (menuPosition === 'left') {
          newLeftPosition = absoluteButtonLeft;
        } else {
          newLeftPosition = absoluteButtonRight - dropdownMenuRect.width;
        }

        // Check if dropdown overflows the window's width.
        // If it does, pin it to the right side of the window.
        if (
          absoluteButtonRight + rightScreenEdgeOffset > windowWidth ||
          newLeftPosition + dropdownMenuRect.width + rightScreenEdgeOffset > windowWidth
        ) {
          newLeftPosition = windowWidth - dropdownMenuRect.width - rightScreenEdgeOffset;
        } else if (newLeftPosition < rightScreenEdgeOffset) {
          newLeftPosition = rightScreenEdgeOffset;
        }

        const newPosition = {
          left: `${newLeftPosition}px`,
          top: `${absoluteButtonTop + dropdownButtonRect.height}px`,
        };

        setDropdownMenuPosition(newPosition);
      }
    }, [isOpen, menuPosition]);

    useEffect(() => {
      updateDropdownMenuPosition();

      const scrollableParent = getScrollableParent(dropdownButtonRef.current);

      window.addEventListener('scroll', updateDropdownMenuPosition);
      window.addEventListener('resize', updateDropdownMenuPosition);
      if (scrollableParent) {
        scrollableParent.addEventListener('scroll', updateDropdownMenuPosition);
      }

      return () => {
        window.removeEventListener('scroll', updateDropdownMenuPosition);
        window.removeEventListener('resize', updateDropdownMenuPosition);
        if (scrollableParent) {
          scrollableParent.removeEventListener('scroll', updateDropdownMenuPosition);
        }
      };
    }, [isOpen, updateDropdownMenuPosition]);

    const onClickOutside = ({ target }) => {
      const dropdownBUtton = dropdownButtonRef.current;
      if (isOpenState.current && !dropdownBUtton?.contains(target)) {
        toggleMenu(!isOpenState.current);
      }
    };

    const dropdownPortalId = useMemo(() => `dropdownPortal-${generateUid()}`, []);

    useEffect(() => {
      toggleMenu(false);
    }, [location]);

    useEffect(() => {
      window.addEventListener('click', onClickOutside);
      return () => window.removeEventListener('click', onClickOutside);
      // eslint-disable-next-line
    }, []);

    let trailingIcon = TrailingIcon;
    let dropdownContentShiftInRems = 0;
    if (['dropdownOpaque', 'dropdownTransparent'].includes(variant)) {
      trailingIcon = <Svg img={chevronDown} size={[1, 0.6]} />;
    }

    // Logic for shifting dropdown content so that content's arrow points exactly at icon's center.
    const trailingIconRef = useRef<HTMLButtonElement>(null);
    if (['icon'].includes(variant) && showArrow) {
      const iconWidth = trailingIconRef.current?.clientWidth || 0;
      const arrowWidth = 14;
      const arrowHorizontalShift = 8;
      const dropdownContentShiftMod = menuPosition === 'right' ? -1 : 1;
      dropdownContentShiftInRems =
        ((iconWidth / 2 - arrowWidth / 2 - arrowHorizontalShift) / 10) * dropdownContentShiftMod;
    }

    return (
      <div
        className={cx(styles.root, styles[`variant--${variant}`], className)}
        data-testid={dataTestId}
      >
        <Button
          ref={dropdownButtonRef}
          isActive={isOpen}
          LeadingIcon={LeadingIcon}
          onClick={() => toggleMenu(!isOpenState.current)}
          TrailingIcon={trailingIcon}
          trailingIconRef={trailingIconRef}
          variant={variant as keyof typeof ButtonVariant}
          {...buttonProps}
        />
        <ReactPortal wrapperId={dropdownPortalId}>
          <div
            ref={dropdownMenuRef}
            className={cx(
              styles.dropdownContent,
              dropdownContentClassname,
              styles[`menuPosition--${menuPosition}`],
              styles[`menuWidth--${menuWidth}`],
              showArrow && styles.showArrow,
              isOpen && styles.isOpen,
            )}
            onClick={e => {
              e.stopPropagation();
              if (!keepOpen) {
                toggleMenu(!isOpenState.current);
              }
            }}
            style={{
              ...dropdownMenuPosition,
              transform: `translateX(${dropdownContentShiftInRems}rem)`,
            }}
          >
            {children}
          </div>
        </ReactPortal>
      </div>
    );
  },
);

export default Dropdown;
