import { ApiTransactionSummary, TransactionStatus, TransactionType } from 'store/api/api.types';

export const shouldShowCancelTransationButton = (
  transactionSummary: ApiTransactionSummary,
): boolean => {
  const { attributes } = transactionSummary;
  const { transactionType, status } = attributes;
  return (
    [TransactionType.Onramp, TransactionType.Offramp].includes(transactionType) &&
    [TransactionStatus.AwaitingFunds, TransactionStatus.AwaitingComplianceReview].includes(status)
  );
};
