import React, { FC } from 'react';

import Button from 'components/core/Button/Button';
import ModalContent from 'components/core/Modal/ModalContent/ModalContent';
import Text from 'components/core/Text/Text';
import modalService from 'services/modalService';

export const modalCreateKeyTitle = 'Create API Key';

const ModalCancelTransaction: FC<{
  handleCancelTransaction: (event: React.FormEvent<HTMLFormElement>) => void;
}> = ({ handleCancelTransaction }) => (
  <form onSubmit={handleCancelTransaction}>
    <ModalContent variant='default'>
      <Text variant='bodyCopyStandard'>
        Cancelling this transaction will halt it immediately, and it will not be processed. This
        action is irreversible. If you wish to proceed with your exchange later, you will need to
        initiate a new transaction.
      </Text>
    </ModalContent>
    <ModalContent variant='footerButtonsWithoutTopBorder'>
      <Button
        label='Go Back'
        onClick={() => {
          modalService.closeCurrentModal();
        }}
        variant='textCta'
      />
      <Button label='Confirm Cancellation' type='submit' variant='destructive' />
    </ModalContent>
  </form>
);

export default ModalCancelTransaction;
