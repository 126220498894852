import { PREBUILT_FILTERS } from 'components/core/DatePicker/DatePicker.types';

export const ORDERED_FILTERS = [
  PREBUILT_FILTERS.LAST_90_DAYS,
  PREBUILT_FILTERS.THIS_MONTH,
  PREBUILT_FILTERS.LAST_MONTH,
  PREBUILT_FILTERS.LAST_WEEK,
  PREBUILT_FILTERS.THIS_YEAR,
  PREBUILT_FILTERS.LAST_YEAR,
  PREBUILT_FILTERS.ALL_TIME,
  PREBUILT_FILTERS.CUSTOM,
];

export const FILTER_LABELS = {
  [PREBUILT_FILTERS.LAST_90_DAYS]: 'Last 90 Days',
  [PREBUILT_FILTERS.THIS_MONTH]: 'This Month',
  [PREBUILT_FILTERS.LAST_MONTH]: 'Last Month',
  [PREBUILT_FILTERS.LAST_WEEK]: 'Last Week',
  [PREBUILT_FILTERS.THIS_YEAR]: 'This Year',
  [PREBUILT_FILTERS.LAST_YEAR]: 'Last Year',
  [PREBUILT_FILTERS.ALL_TIME]: 'All Time',
  [PREBUILT_FILTERS.CUSTOM]: 'Custom',
};

/*
  Our Dropdown component relies on a React portal to present the Dropdown's options list, this makes the options being rendered outside of our Datepicker Hierarchy,
  This behavior leads to a bug that has been detected here: https://gitlab.com/conduit-fi/client-portal-frontend/-/merge_requests/305#note_2287663632

  In order to address this behavior, we add the date-picker class to the dropdowns we use inside of the date picker,
  and check for if it exists in the handleClickOutside in the DatePickerButton component. If the date-picker class does exists, we should not close the calendar
*/
export const DATEPICKER_CLICK_OUTSIDE_DROPDOWN_CLASS_NAME = 'date-picker';
