import { capitalize } from 'lodash';
import { useNavigate } from 'react-router-dom';
import React, { FC, useCallback, useMemo } from 'react';

import { BankSelectProps } from 'components/dedicated/organization/send-money/BankSelect/BankSelect.types';
import { ORGANIZATION_ROUTES } from 'router/constants';
import { SelectFieldOption } from 'components/core/Form/SelectField/SelectField.types';
import { getUriId } from 'utils/format';
import SelectField from 'components/core/Form/SelectField/SelectField';
import Text from 'components/core/Text/Text';

const NO_BANKS = 'NO-BANKS';

const BankSelect: FC<BankSelectProps> = ({
  assetType,
  options,
  bankId,
  bankType,
  error,
  isDisabled,
  onSelect,
  showSublabelForSelected = true,
  supportingText,
}) => {
  const navigate = useNavigate();

  const bankOptions = useMemo<SelectFieldOption[]>(() => {
    return options.length > 0
      ? options
      : [
          {
            component: (
              <Text align='center' variant='bodyCopySmall'>
                You haven&apos;t added a {bankType} with a bank account for this
                <br />
                currency yet or it is currently being reviewed. Please create
                <br />
                one{' '}
                <Text display='inline-block' variant='bodyLinkSmall'>
                  here
                </Text>{' '}
                to continue.
              </Text>
            ),
            label: '',
            value: NO_BANKS,
          },
        ];
  }, [options, bankType]);

  const selectedOption = useMemo(
    () => bankOptions.find(option => option.value === bankId) || null,
    [bankId, bankOptions],
  );

  const selectFieldLabel = useMemo(() => {
    return `${getUriId(assetType)} ${capitalize(bankType)}`;
  }, [assetType, bankType]);

  const selectBank = useCallback(
    (bank: SelectFieldOption | null) => {
      if (bank?.value === NO_BANKS) {
        navigate(`../${ORGANIZATION_ROUTES.SENDERS_RECIPIENTS.relative}`);
        return;
      }
      onSelect(bank);
    },
    [navigate, onSelect],
  );

  return (
    <SelectField
      error={error}
      isDisabled={isDisabled}
      label={selectFieldLabel}
      onSelect={selectBank}
      options={bankOptions}
      showSublabelForSelected={showSublabelForSelected}
      supportingText={supportingText}
      value={selectedOption}
    />
  );
};

export default BankSelect;
