import { FILTER_LABELS } from 'components/core/DatePicker/DatePicker.constants';
import { PREBUILT_FILTERS } from 'components/core/DatePicker/DatePicker.types';

export const DATEPICKER_BUTTON_LABELS = {
  [PREBUILT_FILTERS.LAST_90_DAYS]: FILTER_LABELS.LAST_90_DAYS,
  [PREBUILT_FILTERS.THIS_MONTH]: FILTER_LABELS.THIS_MONTH,
  [PREBUILT_FILTERS.LAST_MONTH]: FILTER_LABELS.LAST_MONTH,
  [PREBUILT_FILTERS.LAST_WEEK]: FILTER_LABELS.LAST_WEEK,
  [PREBUILT_FILTERS.THIS_YEAR]: FILTER_LABELS.THIS_YEAR,
  [PREBUILT_FILTERS.LAST_YEAR]: FILTER_LABELS.LAST_YEAR,
  [PREBUILT_FILTERS.ALL_TIME]: FILTER_LABELS.ALL_TIME,
  [PREBUILT_FILTERS.CUSTOM]: FILTER_LABELS.CUSTOM,
};
