import { Link, NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import React, { Ref, forwardRef, useCallback } from 'react';
import cx from 'classnames';

import { spacingBase } from 'styles/_layout';
import Spinner from 'components/core/Spinner/Spinner';

import { ButtonIconProps, ButtonProps } from './Button.types';
import styles from './Button.module.scss';

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      align,
      children,
      className,
      dataTestId,
      display = 'block',
      href,
      LeadingIcon,
      TrailingIcon,
      leadingIconRef,
      trailingIconRef,
      isActive,
      isDisabled = false,
      isLoading,
      isNavlink = false,
      label,
      marginBottom,
      marginTop,
      onClick,
      rel,
      size = 'normal',
      showLabelWhenLoading,
      target,
      to,
      useWholePathForMatching = false,
      type = 'button',
      variant = 'primary',
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>,
  ) => {
    const style = {
      display: display !== 'block' ? display : undefined,
      marginBottom: marginBottom ? `calc(${marginBottom}*${spacingBase})` : undefined,
      marginTop: marginTop ? `calc(${marginTop}*${spacingBase})` : undefined,
    };

    const shouldShowLabelWhenLoading =
      showLabelWhenLoading !== undefined ? showLabelWhenLoading : !!LeadingIcon;

    const filteredProps = { style };

    const isActionDisabled = isDisabled || isLoading;
    let isMatchedPath = false;

    let Component;
    if (isNavlink) {
      Component = NavLink;
      const resolvedPath = useResolvedPath(to || '');
      isMatchedPath = !!useMatch({ end: useWholePathForMatching, path: resolvedPath.pathname });
      Object.assign(filteredProps, { to });
    } else if (to) {
      Component = Link;
      Object.assign(filteredProps, { to });
    } else if (href) {
      Component = 'a';
      Object.assign(filteredProps, { href, rel, target });
    } else {
      Component = 'button';
      Object.assign(filteredProps, {
        disabled: isActionDisabled,
        type,
      });
    }

    const Icon = useCallback(
      ({ className: iconClassName, IconNode, iconNodeRef, position }: ButtonIconProps) => (
        <span
          ref={iconNodeRef}
          className={cx(
            styles.iconContainer,
            styles[`iconPosition--${position}`],
            (label || children) && styles.isNextToContent,
            iconClassName,
          )}
        >
          {IconNode}
        </span>
      ),
      [label, children],
    );

    return (
      <Component
        ref={ref}
        className={cx(
          styles.button,
          styles[`align--${align}`],
          styles[`size--${size}`],
          styles[`variant--${variant}`],
          !label && !children && (LeadingIcon || TrailingIcon) && styles.hasIconOnly,
          LeadingIcon && styles.hasLeadingIcon,
          shouldShowLabelWhenLoading && styles.showLabelWhenLoading,
          isDisabled && styles.isDisabled,
          isLoading && styles.isLoading,
          (isMatchedPath || isActive) && styles.isActive,
          className,
        )}
        data-testid={dataTestId}
        disabled={isDisabled || isLoading}
        onClick={isActionDisabled ? () => {} : onClick}
        {...filteredProps}
      >
        {isLoading && (
          <Spinner
            className={styles.spinner}
            dataTestId='Button__spinner'
            size='small'
            variant='button'
          />
        )}
        {LeadingIcon && !isLoading && (
          <Icon
            className={styles.leadingIcon}
            IconNode={LeadingIcon}
            iconNodeRef={leadingIconRef}
            position='leading'
          />
        )}
        <span className={styles.label}>
          {label}
          {children}
        </span>
        {TrailingIcon && (
          <Icon IconNode={TrailingIcon} iconNodeRef={trailingIconRef} position='trailing' />
        )}
      </Component>
    );
  },
);

export default Button;
