import { toUpper } from 'lodash';
import React from 'react';

import { ListItemProps } from 'components/core/ListItem/ListItem.types';
import {
  TransactionDepositInstructions,
  TransactionDepositInstructionsType,
} from 'store/api/api.types';
import { addToArrayIf } from 'utils/addToArrayIf';
import { toTitleCase } from 'utils/format';

import styles from './DepositInstructions.module.scss';

export const getInstructionTypeTabLabel = (
  instructionType: TransactionDepositInstructionsType,
  useUSDLocalTabNames: boolean,
): string => {
  switch (instructionType) {
    case TransactionDepositInstructionsType.TED:
    case TransactionDepositInstructionsType.PIX:
    case TransactionDepositInstructionsType.SPEI:
      return toUpper(instructionType);
    case TransactionDepositInstructionsType.International:
      return useUSDLocalTabNames ? toTitleCase('Swift') : toTitleCase(instructionType);
    case TransactionDepositInstructionsType.Local:
      return useUSDLocalTabNames ? toTitleCase('Fedwire') : toTitleCase(instructionType);
    default:
      return toTitleCase(instructionType);
  }
};

export const getInstructionFields = (
  depositInstructions: TransactionDepositInstructions,
): { isRequired?: boolean; label: string; props?: ListItemProps; value: any }[] => {
  const instructions = depositInstructions || {};

  const fields: { label: string; value: any }[] = [];
  const addIf = addToArrayIf(fields);

  if (
    instructions.type === TransactionDepositInstructionsType.Local ||
    instructions.type === TransactionDepositInstructionsType.International
  ) {
    addIf(!!instructions.bank?.name, {
      isRequired: true,
      label: 'Bank',
      props: {
        valueForCopyButton:
          instructions.bank?.address && instructions.bank?.address.street !== ''
            ? `${instructions.bank?.name}, ${instructions.bank?.address.street}, ${instructions.bank?.address.city}, ${instructions.bank?.address.state} ${instructions.bank?.address.postalCode}, ${instructions.bank?.address.country}`
            : instructions.bank?.name,
      },
      value: (
        <>
          {instructions.bank?.name}
          {instructions.bank?.address && instructions.bank?.address.street !== '' && (
            <>
              <br />
              <br />
              {instructions.bank?.address.street}
              <br />
              {instructions.bank?.address.city && `${instructions.bank?.address.city}, `}
              {instructions.bank?.address.state}
              {instructions.bank?.address.postalCode &&
                ` ${instructions.bank?.address.postalCode}, `}
              {instructions.bank?.address.country}
            </>
          )}
        </>
      ),
    });
    addIf(!!instructions.accountHolder?.name, {
      isRequired: true,
      label: 'Account Holder',
      props: {
        valueForCopyButton:
          instructions.accountHolder?.address && instructions.accountHolder?.address.street !== ''
            ? `${instructions.accountHolder?.name}, ${instructions.accountHolder?.address.street}, ${instructions.accountHolder?.address.city}, ${instructions.accountHolder?.address.state} ${instructions.accountHolder?.address.postalCode}, ${instructions.accountHolder?.address.country}`
            : instructions.accountHolder?.name,
      },
      value: (
        <>
          {instructions.accountHolder?.name}
          <br />
          <br />
          {instructions.accountHolder?.address.street}
          <br />
          {instructions.accountHolder?.address.city &&
            `${instructions.accountHolder?.address.city}, `}
          {instructions.accountHolder?.address.state}
          {instructions.accountHolder?.address.postalCode &&
            ` ${instructions.accountHolder?.address.postalCode}, `}
          {instructions.accountHolder?.address.country}
        </>
      ),
    });
    addIf(!!instructions.bank?.accountNumber, {
      isRequired: true,
      label: 'Account Number',
      value: instructions.bank?.accountNumber,
    });
    addIf(
      !!instructions.bank?.routingCode &&
        instructions.type === TransactionDepositInstructionsType.Local,
      {
        isRequired: true,
        label: 'Routing Code',
        value: instructions.bank.routingCode,
      },
    );
    addIf(!!instructions.bank?.swiftCode, {
      label: 'SWIFT Code',
      value: instructions.bank?.swiftCode,
    });
    addIf(!!instructions.bank?.swiftOrBic, {
      isRequired: true,
      label: 'SWIFT/BIC',
      value: instructions.bank?.swiftOrBic,
    });
    addIf(!!instructions.bank?.intermediarySwiftOrBic, {
      isRequired: true,
      label: 'Intermediary SWIFT/BIC',
      value: instructions.bank?.intermediarySwiftOrBic,
    });

    addIf(!!instructions.accountHolder?.taxNumber, {
      label: 'Tax Number',
      value: instructions.accountHolder?.taxNumber,
    });
    addIf(!!instructions.reference, {
      isRequired: true,
      label: 'Reference',
      value: instructions.reference,
    });
  }

  if (instructions.type === TransactionDepositInstructionsType.SPEI) {
    addIf(!!instructions.bank, {
      isRequired: true,
      label: 'Bank Name',
      value: instructions.bank,
    });
    addIf(!!instructions.beneficiary, {
      isRequired: true,
      label: 'Account Holder',
      value: instructions.beneficiary,
    });
    addIf(!!instructions.clabe, {
      isRequired: true,
      label: 'CLABE',
      value: instructions.clabe,
    });
    addIf(!!instructions.rfc, {
      isRequired: true,
      label: 'RFC',
      value: instructions.rfc,
    });
  }

  if (instructions.type === TransactionDepositInstructionsType.TED) {
    addIf(!!instructions.bankName, {
      isRequired: true,
      label: 'Bank Name',
      value: instructions.bankName,
    });
    addIf(!!instructions.agencyCode, {
      isRequired: true,
      label: 'Agency Code',
      value: instructions.agencyCode,
    });
    addIf(!!instructions.accountNumber, {
      isRequired: true,
      label: 'Account Number',
      value: instructions.accountNumber,
    });
    addIf(!!instructions.accountHolder, {
      isRequired: true,
      label: 'Account Holder',
      value: instructions.accountHolder,
    });
    addIf(!!instructions.taxNumber, {
      isRequired: true,
      label: 'Tax Number',
      value: instructions.taxNumber,
    });
  }

  if (instructions.type === TransactionDepositInstructionsType.PIX) {
    addIf(!!instructions.key, {
      label: 'Key',
      props: {
        className: styles.wrap,
      },
      value: instructions.key,
    });
    addIf(!!instructions.expiresAt, {
      label: 'Expiration',
      value: instructions.expiresAt?.toLocaleString(),
    });
  }

  return fields;
};
