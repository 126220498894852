export type TransactionFiltersProps = {
  createdAt?: Record<string, string> | null;
  status?: string | null;
  type?: string | null;
};

export enum TransactionsViewTabs {
  ALL = 'All',
  COMPLETED = 'Completed',
  PENDING = 'Pending',
}
