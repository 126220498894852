import { TransactionsViewTabs } from 'components/views/app/organization/TransactionsView/TransactionsView.types';

export type CheckboxDropdownItem = {
  label: string;
  value: string;
};

export type CheckboxDropdownSection = {
  name: string;
  options: CheckboxDropdownItem[];
};

export type CheckboxDropdownProps = {
  label: string;
  onChange: (values: string[]) => void;
  sections: CheckboxDropdownSection[];
  value?: string[];
};

export type TransactionFiltersProps = {
  activeTab: TransactionsViewTabs;
  onFilterChange: (params: {
    defaultFilterOnly: boolean;
    filters: Record<string, string | Record<string, string>>;
  }) => void;
};

export enum FilterTypes {
  DATE_RANGE = 'dateRange',
  STATUS = 'status',
  TYPE = 'transactionType',
}

export type CheckboxDropdownExportedFunctions = {
  clearSelection: () => void;
};
