import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import { CheckboxDropdown } from 'components/core/CheckboxDropdown/CheckboxDropdown';
import {
  CheckboxDropdownExportedFunctions,
  FilterTypes,
  TransactionFiltersProps,
} from 'components/core/CheckboxDropdown/CheckboxDropdown.types';
import { DatePickerButton } from 'components/core/DatePickerButton/DatePickerButton';
import {
  DatePickerExportedFunctions,
  PREBUILT_FILTERS,
} from 'components/core/DatePicker/DatePicker.types';
import { TransactionsViewTabs } from 'components/views/app/organization/TransactionsView/TransactionsView.types';
import { applyFilterFor } from 'components/core/DatePicker/components/DatepickerPrebuiltFilters.utils';
import {
  buildQueryObject,
  formatDatesForCreatedAtTransactionsQuery,
  returnActiveFilters,
} from 'components/views/app/organization/TransactionsView/components/TransactionFilters/TransactionsFilters.utils';
import { filter } from 'components/core/Svg/icons';
import {
  transactionStatusDropdownSections,
  transactionTypeDropdownSections,
} from 'components/views/app/organization/TransactionsView/TransactionsView.constants';
import Box from 'components/core/Box/Box';
import Button from 'components/core/Button/Button';
import ReactPortal from 'components/core/ReactPortal/ReactPortal';
import Svg from 'components/core/Svg/Svg';
import styles from 'components/views/app/organization/TransactionsView/components/TransactionFilters/TransactionFilters.module.scss';

export const TransactionFilters: FC<TransactionFiltersProps> = ({ onFilterChange, activeTab }) => {
  const defaultDateFilterType = PREBUILT_FILTERS.LAST_90_DAYS;

  const [isActive, setIsActive] = useState(false);
  const [filtersObject, setFiltersObject] = useState({});
  const [isOnlyDefaultFilterApplied, setIsOnlyDefaultFilterApplied] = useState(true);
  const [defaultDateFilter, setDefaultDateFilter] = useState<Record<string, any>>({});

  const statusFilterRef = useRef<CheckboxDropdownExportedFunctions>(null);
  const typeFilterRef = useRef<CheckboxDropdownExportedFunctions>(null);
  const datePickerRef = useRef<DatePickerExportedFunctions>(null);

  const filterSections = useMemo(
    () =>
      activeTab === TransactionsViewTabs.ALL
        ? transactionStatusDropdownSections
        : transactionStatusDropdownSections.filter(({ name }) => name === activeTab),
    [activeTab],
  );
  const activeFilters = useMemo(() => returnActiveFilters({ filtersObject }), [filtersObject]);

  const resetAllFilters = () => {
    statusFilterRef.current?.clearSelection();
    typeFilterRef.current?.clearSelection();
    datePickerRef.current?.clearFilters();
    setFiltersObject({ [FilterTypes.DATE_RANGE]: defaultDateFilter });

    const date = applyFilterFor(defaultDateFilterType, new Date());
    const { newStartDate, newEndDate } = date;
    const createdAtFilter = formatDatesForCreatedAtTransactionsQuery(newStartDate!, newEndDate);
    onFilterChange({
      defaultFilterOnly: true,
      filters: createdAtFilter,
    });

    setIsOnlyDefaultFilterApplied(true);
  };

  const onChange = (filterType: FilterTypes, value: Record<any, any>) => {
    const updatedFilters = {
      ...filtersObject,
      [filterType]: value,
    };

    setFiltersObject(updatedFilters);

    const hasActiveFilters = Object.keys(updatedFilters).length > 0;
    const isOnlyDefaultFilterEnabled =
      !updatedFilters[FilterTypes.STATUS] &&
      !updatedFilters[FilterTypes.TYPE] &&
      updatedFilters[FilterTypes.DATE_RANGE].filter === defaultDateFilterType;
    setIsOnlyDefaultFilterApplied(isOnlyDefaultFilterEnabled);

    // if no active filters are detected, returns to the transactions view no filters applied.
    if (!hasActiveFilters) {
      onFilterChange({
        defaultFilterOnly: false,
        filters: {},
      });
      return;
    }

    const checkboxFilters = buildQueryObject(updatedFilters);
    const hasDateFilter = Object.keys(updatedFilters[FilterTypes.DATE_RANGE])?.length;

    // if the date filter is ALL TIME, it means that we should not pass any created at filters to the query, therefore we return an empty object
    if (
      !hasDateFilter ||
      updatedFilters[FilterTypes.DATE_RANGE]?.filter === PREBUILT_FILTERS.ALL_TIME
    ) {
      onFilterChange({
        defaultFilterOnly: isOnlyDefaultFilterEnabled,
        filters: {
          ...checkboxFilters,
          createdAt: {},
        },
      });
      return;
    }

    const { start, end } = updatedFilters[FilterTypes.DATE_RANGE];
    const createdAtFilter = formatDatesForCreatedAtTransactionsQuery(start, end);

    // in the first load, save the current default filter values to be used in the resetAllFilters method
    if (filterType === FilterTypes.DATE_RANGE && value.isDefaultFilter) {
      setDefaultDateFilter(updatedFilters[FilterTypes.DATE_RANGE]);
    }

    onFilterChange({
      defaultFilterOnly: isOnlyDefaultFilterEnabled,
      filters: {
        ...checkboxFilters,
        ...createdAtFilter,
      },
    });
  };

  // This clears all the filters when the user changes a tab in the transactions table
  useEffect(() => {
    resetAllFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <Box display='flex' flexDirection='column'>
      <Button
        className={styles.filtersToggle}
        LeadingIcon={
          activeFilters.length > 0 && !isOnlyDefaultFilterApplied ? (
            <span className={styles.counter}>{activeFilters.length}</span>
          ) : (
            <Svg img={filter} size={1.8} />
          )
        }
        onClick={() => setIsActive(!isActive)}
        variant='tertiary'
      >
        Filters
      </Button>
      {isActive && (
        <ReactPortal wrapperId='portal-filter'>
          <Box display='flex' gap={4} marginTop={2}>
            <DatePickerButton
              ref={datePickerRef}
              defaultFilter={defaultDateFilterType}
              initialState={filtersObject[FilterTypes.DATE_RANGE]}
              onChange={values => onChange(FilterTypes.DATE_RANGE, values)}
            />
            <CheckboxDropdown
              ref={typeFilterRef}
              label='Type'
              onChange={values => onChange(FilterTypes.TYPE, values)}
              sections={transactionTypeDropdownSections}
              value={filtersObject[FilterTypes.TYPE]}
            />

            <CheckboxDropdown
              ref={statusFilterRef}
              label='Status'
              onChange={values => onChange(FilterTypes.STATUS, values)}
              sections={filterSections}
              value={filtersObject[FilterTypes.STATUS]}
            />

            {activeFilters.length > 0 && !isOnlyDefaultFilterApplied && (
              <Button onClick={() => resetAllFilters()} variant='textCta'>
                Clear All
              </Button>
            )}
          </Box>
        </ReactPortal>
      )}
    </Box>
  );
};
