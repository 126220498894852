import { useDispatch } from 'react-redux';
import React, { ReactNode } from 'react';

import { CancelTransactionButtonProps } from 'components/dedicated/CancelTransactionButton/CancelTransactionButton.types';
import { TRANSACTION_TYPE_LABELS } from 'constants/transactions';
import { TransactionStatus } from 'store/api/api.types';
import { getParsedTransactionAttributes } from 'helpers/transaction.helpers';
import { platformApi, useCancelTransactionMutation } from 'store/api/platformApi';
import { showError, showSuccess } from 'services/notificationService';
import Box from 'components/core/Box/Box';
import Button from 'components/core/Button/Button';
import ModalCancelTransaction from 'components/dedicated/ModalCancelTransaction/ModalCancelTransaction';
import Text from 'components/core/Text/Text';
import Tooltip from 'components/core/Tooltip/Tooltip';
import modalService from 'services/modalService';

export const CancelTransactionButton = ({
  transactionSummary,
  organizationId,
  tooltipPosition = 'top',
  marginTop,
  ...buttonProps
}: CancelTransactionButtonProps): ReactNode => {
  const {
    id: transactionId,
    attributes: { status: transactionStatus, cancellable: isCancellable },
  } = transactionSummary;

  const { attributes } = transactionSummary;
  const { sourceAmount, sourceAsset, transactionType } = getParsedTransactionAttributes(attributes);

  const [cancelTransaction, { isLoading, isSuccess }] = useCancelTransactionMutation({
    fixedCacheKey: `cancelTransaction-${transactionId}`,
  });
  const dispatch = useDispatch();

  const handleCancelTransaction = async e => {
    e.preventDefault();
    modalService.closeCurrentModal();
    const response = await cancelTransaction({ organizationId, transactionId });

    if (!('error' in response)) {
      showSuccess(
        <Text variant='bodyCopySmall'>
          <strong>Your request to cancel the transaction is being processed.</strong> Please refresh
          the page in a few moments to confirm the status.
        </Text>,
      );

      setTimeout(() => {
        dispatch(
          platformApi.util.invalidateTags([
            { id: organizationId, type: 'TransactionSummaries' },
            { id: transactionId, type: 'TransactionSummary' },
            { id: organizationId, transactionId, type: 'TransactionEvents' } as any,
          ]),
        );
      }, 2000);
    } else {
      showError(
        <Text variant='bodyCopySmall'>
          <strong>
            The {sourceAmount} {sourceAsset}{' '}
            {TRANSACTION_TYPE_LABELS[transactionType].toLowerCase()} couldn&apos;t be cancelled.
          </strong>{' '}
          It may already be processing or completed, or there might be a system issue. Please
          refresh and try again. If the problem persists, contact support.
        </Text>,
        { autoClose: 10000 },
      );
    }
  };

  const cancellableStatus = [
    TransactionStatus.AwaitingFunds,
    TransactionStatus.AwaitingComplianceReview,
    TransactionStatus.InComplianceReview,
    TransactionStatus.Created,
    TransactionStatus.ProcessingWithdrawal,
  ];

  const isDisabled = !cancellableStatus.includes(transactionStatus) || !isCancellable;
  const showLoadingState = isSuccess || isLoading;

  return (
    <>
      <Tooltip
        display='inline-flex'
        isHidden={!isDisabled || showLoadingState}
        label='This transaction can no longer be cancelled.'
        marginTop={marginTop}
        position={tooltipPosition}
      >
        <Button
          isDisabled={isDisabled}
          isLoading={showLoadingState}
          variant='destructiveSecondary'
          {...buttonProps}
          onClick={() =>
            modalService.openModal(
              { title: 'Are you sure?' },
              <ModalCancelTransaction handleCancelTransaction={handleCancelTransaction} />,
            )
          }
          showLabelWhenLoading
        >
          {showLoadingState ? 'Processing Cancellation...' : 'Cancel Transaction'}
        </Button>
      </Tooltip>
      {showLoadingState && (
        <Box marginTop={4}>
          <Text color='charcoalDarker' variant='subCopyExtraSmall'>
            <strong>Cancellation may take up to a minute.</strong>
            <br />
            Please refresh the page periodically for the most updated transaction status.
          </Text>
        </Box>
      )}
    </>
  );
};
