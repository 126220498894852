import { AssetType } from 'constants/assetTypes.types';
import { BankAccount } from 'store/api/api.types';
import { SelectFieldOption } from 'components/core/Form/SelectField/SelectField.types';
import { getBankNameWithAccountNumberPart } from 'helpers/transaction.helpers';
import { getUriId } from 'utils/format';

export const getBankAccountsForAssetType = (
  bankAccounts: BankAccount[],
  assetType?: AssetType | false,
): SelectFieldOption[] =>
  bankAccounts!
    .filter(bankAccount =>
      assetType ? bankAccount.attributes.currency === getUriId(assetType) : true,
    )
    .map(bankAccount => ({
      data: { currency: `asset:fiat:${bankAccount.attributes.currency}` },
      label: bankAccount.attributes.nameOnBankAccount,
      sublabel: getBankNameWithAccountNumberPart(bankAccount.attributes),
      value: bankAccount.id,
    }));
