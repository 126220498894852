import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import React, { FC, useEffect } from 'react';

import { QuoteViewStep } from 'components/views/app/organization/SendMoney/QuoteView/QuoteView.types';
import { SendMoneyOutletContext } from 'components/views/app/organization/SendMoney/SendMoney.types';
import { TransactionType } from 'store/api/api.types';
import { formatAssetType, getUriIdAndChainId, isFiat } from 'utils/format';
import { isFetchBaseQueryErrorType, isGenericErrorType } from 'store/api/api.utils';
import { showError } from 'services/notificationService';
import { showSpecificError } from 'utils/showSpecificError';
import { useCreateTransactionContext } from 'context/CreateTransactionContext';
import { useCreateTransactionMutation } from 'store/api/platformApi';
import Box from 'components/core/Box/Box';
import Button from 'components/core/Button/Button';
import List from 'components/core/List/List';
import ListItem from 'components/core/ListItem/ListItem';
import LoaderText from 'components/core/LoaderText/LoaderText';
import Spinner from 'components/core/Spinner/Spinner';
import Text from 'components/core/Text/Text';

import { QuotePreviewProps } from './QuotePreview.types';
import styles from './QuotePreview.module.scss';

import { hasColumnAgreementClientTags } from '../ColumnAgreement/ColumnAgreement.utils';
import { transactionPurposeLabels } from '../QuoteForm/QuoteForm.constants';
import LiquidityLimitMessage from '../LiquidityLimitMessage/LiquidityLimitMessage';

const QuotePreview: FC<QuotePreviewProps> = ({ changeStep, organization }) => {
  const navigate = useNavigate();
  const { accountId } = useOutletContext<SendMoneyOutletContext>();
  const { organizationId } = useParams() as { organizationId: string };
  const {
    isQuoteInProgress,
    liquidityLimitStatus,
    quote,
    quoteStatusLabel,
    recipient,
    recipientBankLabel,
    recipientLabel,
    recipientSublabel,
    reference,
    sender,
    senderBankLabel,
    senderLabel,
    senderSublabel,
    supportingDocumentFile,
    transactionPurpose,
  } = useCreateTransactionContext();
  const [quoteDetails, setQuoteDetails] = React.useState<{
    actionDescription: string;
    amountToReceive: string;
    amountToSend: string;
  }>({
    actionDescription: '',
    amountToReceive: '',
    amountToSend: '',
  });

  const [createTransaction, { isLoading: isAcceptingQuote }] = useCreateTransactionMutation();

  useEffect(() => {
    if (!isQuoteInProgress) {
      setQuoteDetails({
        actionDescription:
          quote?.attributes.source.assetType.indexOf('fiat') === -1
            ? `Upon confirmation, ${getUriIdAndChainId(quote.attributes.source.assetType)} will be deducted from your balance and ${getUriIdAndChainId(quote?.attributes.target.assetType)} will be sent to the recipient's bank account.`
            : `Upon confirmation, the quote will be locked and you'll receive detailed instructions for sending ${getUriIdAndChainId(quote?.attributes.source.assetType)}.`,
        amountToReceive: formatAssetType(quote?.attributes.target),
        amountToSend: formatAssetType(quote?.attributes.source),
      });
    }
  }, [isQuoteInProgress, quote]);

  const acceptQuoteOnRamp = async () => {
    const result = await createTransaction({
      amount: quote!.attributes.target.amount,
      assetType: quote!.attributes.target.assetType,
      destination: recipient?.value,
      file: supportingDocumentFile,
      organizationId,
      purpose: transactionPurpose,
      quote: quote!.id,
      reference,
      source: quote!.attributes.sourceBankId || '',
      type: TransactionType.Onramp,
    });
    if (!('error' in result)) {
      navigate(result.data.id);
    } else if (isGenericErrorType(result.error)) {
      showError('Transaction cannot be confirmed. Please try again later.');
    } else if (isFetchBaseQueryErrorType(result.error)) {
      showSpecificError(result.error);
    }
  };

  const acceptQuoteOffRamp = async () => {
    if (accountId) {
      const result = await createTransaction({
        amount: quote!.attributes.source.amount,
        assetType: quote!.attributes.source.assetType,
        destination: quote!.attributes.targetBankId || '',
        file: supportingDocumentFile,
        organizationId,
        purpose: transactionPurpose,
        quote: quote!.id,
        reference,
        source: sender?.value,
        type: TransactionType.Offramp,
      });
      if (!('error' in result)) {
        navigate(result.data.id);
      } else if (isGenericErrorType(result.error)) {
        showError('Transaction cannot be confirmed. Please try again later.');
      } else if (isFetchBaseQueryErrorType(result.error)) {
        showSpecificError(result.error);
      }
    } else {
      showError('Transaction cannot be confirmed. Missing account information.');
    }
  };

  const acceptQuoteFx = async () => {
    const result = await createTransaction({
      amount: quote!.attributes.target.amount,
      assetType: quote!.attributes.target.assetType,
      destination: quote!.attributes.targetBankId || '',
      file: supportingDocumentFile,
      organizationId,
      purpose: transactionPurpose,
      quote: quote!.id,
      reference,
      source: quote!.attributes.sourceBankId || '',
    });
    if (!('error' in result)) {
      navigate(result.data.id);
    } else if (isGenericErrorType(result.error)) {
      showError('Transaction cannot be confirmed. Please try again later.');
    } else if (isFetchBaseQueryErrorType(result.error)) {
      showSpecificError(result.error);
    }
  };

  const acceptQuote = async () => {
    if (quote) {
      if (isFiat(quote.attributes.source.assetType) && isFiat(quote.attributes.target.assetType)) {
        acceptQuoteFx();
      } else if (isFiat(quote.attributes.target.assetType)) {
        acceptQuoteOffRamp();
      } else {
        acceptQuoteOnRamp();
      }
    } else {
      showError('Transaction cannot be confirmed. Missing quote.');
    }
  };

  return (
    <>
      <Text align='center' variant='sectionHeaderLarge'>
        Review & Confirm
      </Text>
      <LiquidityLimitMessage liquidityLimitStatus={liquidityLimitStatus} />
      <Text align='center' marginBottom={5} marginTop={5} variant='bodyCopySmall'>
        {quoteDetails.actionDescription}
      </Text>
      <List className={styles.list}>
        <ListItem label='Send'>
          {!isQuoteInProgress ? (
            quoteDetails.amountToSend
          ) : (
            <Spinner display='inline-block' size='small' />
          )}
        </ListItem>
        <ListItem label='Receive'>
          {!isQuoteInProgress ? (
            quoteDetails.amountToReceive
          ) : (
            <Spinner display='inline-block' size='small' />
          )}
        </ListItem>
        {senderLabel && (
          <ListItem label='Sender'>
            {senderLabel}
            {senderBankLabel}
            {senderSublabel && (
              <Text marginTop={1} variant='subCopySmall'>
                {senderSublabel}
              </Text>
            )}
          </ListItem>
        )}
        {recipientLabel && (
          <ListItem label='Recipient'>
            {recipientLabel}
            {recipientBankLabel}
            {recipientSublabel && (
              <Text marginTop={1} variant='subCopySmall'>
                {recipientSublabel}
              </Text>
            )}
          </ListItem>
        )}
        {transactionPurpose && (
          <ListItem label='Purpose'>{transactionPurposeLabels[transactionPurpose]}</ListItem>
        )}
        {supportingDocumentFile && (
          <ListItem label='Document'>{supportingDocumentFile.name}</ListItem>
        )}
        {reference && <ListItem label='Reference'>{reference}</ListItem>}
      </List>
      {quoteStatusLabel && (
        <LoaderText
          align='center'
          isLoading={isQuoteInProgress}
          label={quoteStatusLabel}
          marginBottom={5}
          marginTop={5}
          variant='bodyCopySmall'
        />
      )}

      <div className={styles.footer}>
        {hasColumnAgreementClientTags(organization) && (
          <Text align='center' marginBottom={5} marginTop={5} variant='bodyCopySmall'>
            By confirming this transaction, I acknowledge that I have read and agree to the updated{' '}
            <Text
              display='inline-flex'
              onClick={() => changeStep(QuoteViewStep.AGREEMENT)}
              variant='bodyLinkSmall'
            >
              Terms and Conditions
            </Text>
            .
          </Text>
        )}
        <Box className={styles.buttons} marginTop={4}>
          <Button
            isDisabled={isAcceptingQuote}
            label='Go Back'
            onClick={() => changeStep(QuoteViewStep.FORM)}
            type='button'
            variant='tertiary'
          />
          <Button
            isDisabled={isAcceptingQuote || liquidityLimitStatus.isOverLimit}
            isLoading={isAcceptingQuote}
            label='Confirm'
            onClick={acceptQuote}
            type='submit'
            variant='primary'
          />
        </Box>
      </div>
    </>
  );
};

export default QuotePreview;
