import React, { FC } from 'react';
import cx from 'classnames';

import { TabProps } from 'components/core/Tab/Tab.types';
import { arrowRight, crossmark } from 'components/core/Svg/icons';
import { getParsedTransactionAttributes } from 'helpers/transaction.helpers';
import Box from 'components/core/Box/Box';
import Button from 'components/core/Button/Button';
import Svg from 'components/core/Svg/Svg';
import Tabs from 'components/core/Tabs/Tabs';
import Text from 'components/core/Text/Text';
import TransactionDescription from 'components/dedicated/TransactionDescription/TransactionDescription';
import TransactionStatusBadge from 'components/dedicated/TransactionStatusBadge/TransactionStatusBadge';
import TransactionTypeIcon from 'components/dedicated/TransactionTypeIcon/TransactionTypeIcon';
import slideoutService from 'services/slideoutService';

import { SlideoutTransactionDetailsHeaderProps } from './SlideoutTransactionDetailsHeader.types';
import styles from './SlideoutTransactionDetailsHeader.module.scss';

const SlideoutTransactionDetailsHeader: FC<SlideoutTransactionDetailsHeaderProps> = ({
  className,
  dataTestId,
  transactionSummary,
  currentTab,
  setCurrentTab,
}) => {
  const { attributes } = transactionSummary;
  const { cancellationStatus } = attributes;
  const {
    senderLabel,
    sourceAmount,
    sourceAsset,
    recipientLabel,
    status,
    targetAsset,
    targetAmount,
    transactionType,
  } = getParsedTransactionAttributes(attributes);

  const tabs: TabProps[] = [
    {
      isActive: currentTab === 'activity',
      label: 'Activity',
      onClick: () => setCurrentTab('activity'),
    },
    {
      isActive: currentTab === 'details',
      label: 'Details',
      onClick: () => setCurrentTab('details'),
    },
  ];

  return (
    <div className={cx(styles.root, className)} data-testid={dataTestId}>
      <div className={styles.contentWrapper}>
        <Button
          className={styles.closeButton}
          LeadingIcon={<Svg img={crossmark} size={1.4} />}
          onClick={() => slideoutService.closeCurrentModal()}
          variant='icon'
        />
        <Box display='flex' marginBottom={6}>
          <TransactionTypeIcon
            className={styles.transactionTypeIcon}
            marginRight={4}
            transactionType={transactionType}
          />
          <TransactionDescription
            marginRight={4}
            sourceAsset={sourceAsset}
            targetAsset={targetAsset}
            transactionType={transactionType}
          />
          <TransactionStatusBadge
            cancellationStatus={cancellationStatus}
            size='small'
            status={status}
          />
        </Box>
        <Box display='flex'>
          <div className={styles.column}>
            <Text marginBottom={2} variant='subCopySmall'>
              From
            </Text>
            <Text marginBottom={2} variant='sectionHeaderLarge'>
              <span className={styles.amount}>{sourceAmount}</span> {sourceAsset}
            </Text>
            <Text variant='subCopySmall'>{senderLabel}</Text>
          </div>
          <Box marginLeft={12} marginRight={12}>
            <Svg className={styles.arrowIcon} img={arrowRight} size={1.6} />
          </Box>
          <div className={styles.column}>
            <Text marginBottom={2} variant='subCopySmall'>
              To
            </Text>
            <Text marginBottom={2} variant='sectionHeaderLarge'>
              <span className={styles.amount}>{targetAmount}</span> {targetAsset}
            </Text>
            <Text variant='subCopySmall'>{recipientLabel}</Text>
          </div>
        </Box>
      </div>
      <Tabs tabs={tabs} variant='secondary' />
    </div>
  );
};

export default SlideoutTransactionDetailsHeader;
