import {
  PREBUILT_FILTERS,
  PrebuiltFilterDateObject,
} from 'components/core/DatePicker/DatePicker.types';

const last90DaysFilter = (today: Date): PrebuiltFilterDateObject => {
  const newStartDate = new Date();
  newStartDate.setDate(today.getDate() - 90);
  return { newEndDate: today, newStartDate };
};

const thisMonthFilter = (today: Date): PrebuiltFilterDateObject => {
  const newStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
  return { newEndDate: new Date(today), newStartDate };
};

const lastMonthFilter = (today: Date): PrebuiltFilterDateObject => {
  const newStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const newEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
  return { newEndDate, newStartDate };
};

const lastWeekFilter = (today: Date): PrebuiltFilterDateObject => {
  const lastSunday = new Date(today);
  lastSunday.setDate(today.getDate() - today.getDay() - 7);

  const lastSaturday = new Date(lastSunday);
  lastSaturday.setDate(lastSunday.getDate() + 6);

  return {
    newEndDate: lastSaturday,
    newStartDate: lastSunday,
  };
};

const thisYearFilter = (today: Date): PrebuiltFilterDateObject => {
  const newStartDate = new Date(today.getFullYear(), 0, 1);
  return { newEndDate: today, newStartDate };
};

const lastYearFilter = (today: Date): PrebuiltFilterDateObject => {
  const newStartDate = new Date(today.getFullYear() - 1, 0, 1);
  const newEndDate = new Date(today.getFullYear() - 1, 11, 31);
  return { newEndDate, newStartDate };
};

const allTimeFilter = (today: Date): PrebuiltFilterDateObject => {
  return { newEndDate: new Date(today), newStartDate: new Date('01/01/2021') };
};

const customFilter = (): PrebuiltFilterDateObject => {
  return { newEndDate: null, newStartDate: null };
};

export const applyFilterFor = (key: PREBUILT_FILTERS, today: Date): PrebuiltFilterDateObject => {
  const filter = {
    [PREBUILT_FILTERS.LAST_90_DAYS]: last90DaysFilter,
    [PREBUILT_FILTERS.THIS_MONTH]: thisMonthFilter,
    [PREBUILT_FILTERS.LAST_MONTH]: lastMonthFilter,
    [PREBUILT_FILTERS.LAST_WEEK]: lastWeekFilter,
    [PREBUILT_FILTERS.THIS_YEAR]: thisYearFilter,
    [PREBUILT_FILTERS.LAST_YEAR]: lastYearFilter,
    [PREBUILT_FILTERS.ALL_TIME]: allTimeFilter,
    [PREBUILT_FILTERS.CUSTOM]: customFilter,
  }[key];

  return filter(today);
};
