import React, { FC } from 'react';
import cx from 'classnames';

import {
  DatePickerPrebuiltFiltersProps,
  PREBUILT_FILTERS,
} from 'components/core/DatePicker/DatePicker.types';
import { FILTER_LABELS, ORDERED_FILTERS } from 'components/core/DatePicker/DatePicker.constants';
import { applyFilterFor } from 'components/core/DatePicker/components/DatepickerPrebuiltFilters.utils';
import Box from 'components/core/Box/Box';
import Button from 'components/core/Button/Button';

import styles from '../DatePicker.module.scss';

export const DatePickerPrebuiltFilters: FC<DatePickerPrebuiltFiltersProps> = ({
  onFilterChange,
  activeFilter,
  setActiveFilter,
  onClickCustomFilter,
  today,
}) => {
  const onChange = (key: PREBUILT_FILTERS) => {
    if (key === PREBUILT_FILTERS.CUSTOM) {
      onClickCustomFilter();
      return;
    }

    const { newEndDate, newStartDate } = applyFilterFor(key, today);

    newEndDate?.setHours(0, 0, 0, 0);
    newStartDate?.setHours(0, 0, 0, 0);

    onFilterChange({
      filter: key,
      newEndDate,
      newStartDate,
    });

    setActiveFilter(key);
  };

  const getLabelText = (filter: PREBUILT_FILTERS) =>
    ({
      [PREBUILT_FILTERS.LAST_90_DAYS]: FILTER_LABELS.LAST_90_DAYS,
      [PREBUILT_FILTERS.THIS_MONTH]: FILTER_LABELS.THIS_MONTH,
      [PREBUILT_FILTERS.LAST_MONTH]: FILTER_LABELS.LAST_MONTH,
      [PREBUILT_FILTERS.LAST_WEEK]: FILTER_LABELS.LAST_WEEK,
      [PREBUILT_FILTERS.THIS_YEAR]: FILTER_LABELS.THIS_YEAR,
      [PREBUILT_FILTERS.LAST_YEAR]: FILTER_LABELS.LAST_YEAR,
      [PREBUILT_FILTERS.ALL_TIME]: FILTER_LABELS.ALL_TIME,
      [PREBUILT_FILTERS.CUSTOM]: FILTER_LABELS.CUSTOM,
    })[filter];

  return (
    <Box alignItems='flex-start' display='flex' flexDirection='column'>
      {ORDERED_FILTERS.map(key => (
        <Button
          key={key}
          className={cx(
            styles.prebuiltFilterButton,
            activeFilter === PREBUILT_FILTERS[key] && styles.prebuiltFilterButtonActive,
          )}
          onClick={() => onChange(key as PREBUILT_FILTERS)}
          size='small'
          variant='textCta'
        >
          {getLabelText(key as PREBUILT_FILTERS)}
        </Button>
      ))}
    </Box>
  );
};
