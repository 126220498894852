import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

export enum PREBUILT_FILTERS {
  ALL_TIME = 'ALL_TIME',
  CUSTOM = 'CUSTOM',
  LAST_90_DAYS = 'LAST_90_DAYS',
  LAST_MONTH = 'LAST_MONTH',
  LAST_WEEK = 'LAST_WEEK',
  LAST_YEAR = 'LAST_YEAR',
  THIS_MONTH = 'THIS_MONTH',
  THIS_YEAR = 'THIS_YEAR',
}

export type DateChangeProps = {
  filter: PREBUILT_FILTERS;
  newEndDate?: Date | null | undefined;
  newStartDate?: Date | null | undefined;
};

export type DatePickerPrebuiltFiltersProps = {
  activeFilter: PREBUILT_FILTERS;
  onClickCustomFilter: () => void;
  onFilterChange: (params: DateChangeProps) => void;
  setActiveFilter: (value: PREBUILT_FILTERS) => void;
  today: Date;
};

export interface DatePickerHeaderProps extends ReactDatePickerCustomHeaderProps {
  activeFilter?: PREBUILT_FILTERS | null;
  endDate?: Date | null;
  startDate?: Date | null;
  today: Date;
}

export type DatePickerOnChangeProps = {
  activeFilter?: PREBUILT_FILTERS | null | undefined;
  buttonLabel?: string | null | undefined;
  endDate?: Date | null | undefined;
  isSingleDate?: boolean;
  startDate?: Date | null | undefined;
};

export type DatePickerProps = {
  initialFilter?: PREBUILT_FILTERS | null;
  onChange: (params: DatePickerOnChangeProps) => void;
  onClearFilters?: () => void;
  selectedEndDate?: Date | null;
  selectedStartDate?: Date | null;
};

export type DatePickerExportedFunctions = {
  clearFilters: () => void;
};
export type DatePickerHeadlessExportedFunctions = {
  changeMonthView: (params: { appliedFilter: PREBUILT_FILTERS; endDate?: Date | null }) => void;
  changeYearView: (year: number) => void;
};

export type IsInHoverRangeProps = {
  date: Date;
  endDate?: Date | null;
  hoveredDate?: Date | null;
  startDate?: Date | null;
};

export type GetDayStyleClassProps = {
  date: Date;
  endDate?: Date | null;
  hoveredDate?: Date | null;
  startDate?: Date | null;
};

export type PrebuiltFilterDateObject = {
  newEndDate: Date | null;
  newStartDate: Date | null;
};

export type DatePickerButtonProps = {
  defaultFilter?: PREBUILT_FILTERS;
  initialState?: any;
  onChange: (params: DatePickerButtonOnChangeProps) => void;
  onClearFilters?: () => void;
};

export type DatePickerButtonOnChangeProps = {
  end?: Date | null | undefined;
  filter?: PREBUILT_FILTERS | null | undefined;
  isDefaultFilter: boolean;
  isSingleDate?: boolean;
  label?: string | null | undefined;
  start?: Date | null | undefined;
};
