import cloneDeep from 'lodash/cloneDeep';

import { ACTIVE_COUNTRIES_OPTIONS, COUNTRIES } from 'constants/countries';
import { BankAccountAttributes } from 'store/api/api.types';
import { CurrencySelectFieldProps } from 'components/core/Form/CurrencySelectField/CurrencySelectField.types';
import { SelectFieldProps } from 'components/core/Form/SelectField/SelectField.types';
import CurrencySelectField from 'components/core/Form/CurrencySelectField/CurrencySelectField';

import { BANK_ACCOUNT_TYPE_OPTIONS, CHECKING_BANK_ACCOUNT_OPTION } from './bankDetails.constants';
import { BRL_BANKS_OPTIONS, COL_BANKS_OPTIONS } from './banks';
import { BankDetailsComponentId, BankDetailsComponents } from './bankDetails.types';
import { ComponentConfigWithId } from './types';
import { createSetIsVisibleHelper } from './helpers';
import { getAvailableAssetTypesByBankCountry } from './currencies';
import RoutingCodeInput from './components/RoutingCodeInput';
import SelectField from './components/SelectField';
import TextInput from './components/TextInput';

import { BankDetails } from '../FormBankDetails/FormBankDetails.types';

const defaultBankDetailsComponentsConfig: BankDetailsComponents = {
  accountNumber: {
    Component: TextInput,
    isVisible: true,
    props: {
      label: 'Account Number',
    },
  },
  accountType: {
    Component: SelectField,
    isVisible: true,
    props: {
      label: 'Account Type',
      options: BANK_ACCOUNT_TYPE_OPTIONS,
    },
  },
  bankName: {
    Component: TextInput,
    isVisible: true,
    props: {
      label: 'Bank Name',
    },
  },
  bicSwiftCode: {
    Component: TextInput,
    props: {
      label: 'BIC / Swift Code',
    },
  },
  country: {
    Component: SelectField,
    isVisible: true,
    props: {
      label: 'Country',
      options: ACTIVE_COUNTRIES_OPTIONS,
    },
  },
  currency: {
    Component: CurrencySelectField,
    isVisible: true,
    props: {
      label: 'Currency',
    },
  },
  nameOnBankAccount: {
    Component: TextInput,
    isVisible: true,
    props: {
      label: 'Name on Bank Account',
    },
  },
  routingCode: {
    Component: RoutingCodeInput,
    props: {
      label: 'Routing number',
      tooltipLabel:
        'A 9-digit code used to identify US banks. This can usually be found on the bottom left of checks or ask the bank for it.',
    },
  },
};

export const bankDetailsComponentsOrder: BankDetailsComponentId[] = [
  BankDetailsComponentId.country,
  BankDetailsComponentId.currency,
  BankDetailsComponentId.nameOnBankAccount,
  BankDetailsComponentId.bankName,
  BankDetailsComponentId.accountType,
  BankDetailsComponentId.accountNumber,
  BankDetailsComponentId.routingCode,
  BankDetailsComponentId.bicSwiftCode,
];

export const getBankDetailsFormConfig = (
  bankDetails: BankDetails | BankAccountAttributes,
): ComponentConfigWithId<BankDetailsComponentId>[] => {
  const components = cloneDeep(defaultBankDetailsComponentsConfig);
  const setIsVisible = createSetIsVisibleHelper(components);
  const { country, currency } = bankDetails;

  components[BankDetailsComponentId.currency].props.isDisabled = !bankDetails.country;
  (components[BankDetailsComponentId.currency].props as CurrencySelectFieldProps).assetTypes =
    bankDetails.country ? getAvailableAssetTypesByBankCountry(bankDetails.country) : [];

  if (country !== 'USA' && currency === 'USD') {
    setIsVisible(BankDetailsComponentId.bicSwiftCode);
    setIsVisible(BankDetailsComponentId.routingCode);
    components[BankDetailsComponentId.routingCode].props.isRequired = false;
    components[BankDetailsComponentId.routingCode].props.label = 'Routing Number (Optional)';
    components[BankDetailsComponentId.routingCode].props.tooltipLabel =
      'Enter a routing number to enable transactions via FedWire.';
  }

  if (country === 'USA') {
    setIsVisible(BankDetailsComponentId.routingCode);
  }

  if (country === 'MEX' && currency === 'MXN') {
    components[BankDetailsComponentId.accountType].props = {
      ...components[BankDetailsComponentId.accountType].props,
      isHidden: true,
      options: [CHECKING_BANK_ACCOUNT_OPTION],
      selectedDefault: CHECKING_BANK_ACCOUNT_OPTION,
    };
    components[BankDetailsComponentId.accountNumber].props.label = 'CLABE';
  }

  if (country === 'BRA' && currency === 'BRL') {
    setIsVisible(BankDetailsComponentId.routingCode);
    components[BankDetailsComponentId.routingCode].Component = TextInput;
    components[BankDetailsComponentId.routingCode].props.label = 'Branch Code';

    components[BankDetailsComponentId.accountNumber].props.placeholder = '12345678-9';

    components[BankDetailsComponentId.bankName].Component = SelectField;
    (components[BankDetailsComponentId.bankName].props as SelectFieldProps).options =
      BRL_BANKS_OPTIONS;
  }

  if (country === 'COL' && currency === 'COP') {
    setIsVisible(BankDetailsComponentId.routingCode);
    components[BankDetailsComponentId.routingCode].Component = TextInput;
    components[BankDetailsComponentId.routingCode].props.label = 'Bank Code';

    components[BankDetailsComponentId.accountNumber].props.placeholder = '1013555555';

    components[BankDetailsComponentId.bankName].Component = SelectField;
    (components[BankDetailsComponentId.bankName].props as SelectFieldProps).options =
      COL_BANKS_OPTIONS;
  }

  // return components in the order specified in bankDetailsComponentsOrder if they are visible
  return bankDetailsComponentsOrder
    .filter(componentId => components[componentId].isVisible)
    .map(componentId => {
      return {
        ...components[componentId],
        id: componentId,
      };
    });
};

export const getFilteredBankDetailFormValues = (
  bankDetails: BankDetails | BankAccountAttributes,
): BankDetails => {
  const config = getBankDetailsFormConfig(bankDetails);
  // Allow only keys that are present in the config:
  const allowedKeys = config.map(({ id }) => id);

  return Object.keys(bankDetails).reduce((acc, key) => {
    if (allowedKeys.includes(key as BankDetailsComponentId)) {
      return {
        ...acc,
        [key]: bankDetails[key],
      };
    }
    return acc;
  }, {} as BankDetails);
};

export const getMandatoryBankDetailsFields = (
  bankDetails: BankDetails | BankAccountAttributes,
): BankDetails => {
  const config = getBankDetailsFormConfig(bankDetails);

  const mandatoryKeys = config
    .filter(component => component.props.isRequired)
    .map(component => component.id);
  return Object.keys(bankDetails).reduce((acc, key) => {
    if (mandatoryKeys.includes(key as BankDetailsComponentId)) {
      return {
        ...acc,
        [key]: bankDetails[key],
      };
    }
    return acc;
  }, {} as BankDetails);
};

const getFieldValueByComponentId = (componentId: BankDetailsComponentId, value: string) => {
  switch (componentId) {
    case BankDetailsComponentId.country:
      return COUNTRIES.find(country => country.code === value)?.name;
    case BankDetailsComponentId.accountType:
      return BANK_ACCOUNT_TYPE_OPTIONS.find(option => option.value === value)?.label;
    default:
      return value;
  }
};

/**
 * Filters out the bank details that are not visible in the form
 * structure and returns the rest in order, with labels and values.
 */
export const getBankDetailsWithLabels = (
  bankDetails: BankDetails | BankAccountAttributes,
): { id: BankDetailsComponentId; label: string; value: string }[] => {
  const config = getBankDetailsFormConfig(bankDetails);
  const filteredDetails = getFilteredBankDetailFormValues(bankDetails);

  return bankDetailsComponentsOrder
    .filter(componentId => config.find(component => component.id === componentId))
    .map(componentId => {
      return {
        id: componentId,
        label: config.find(component => component.id === componentId)?.props?.label || '',
        value: getFieldValueByComponentId(componentId, filteredDetails[componentId]) || '',
      };
    });
};
